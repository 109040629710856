import { isValid } from '@/utils/input-validation'

export const Input = () => {
  const inputs = document.querySelectorAll(
    '[data-component="dfi-input"] input, [data-component="dfi-input"] textarea'
  ) as NodeListOf<HTMLInputElement | HTMLTextAreaElement>
  if (inputs.length === 0) return

  // Handle input value state for label animation
  inputs.forEach((input: HTMLInputElement | HTMLTextAreaElement) => {
    toggleInputHasValue(input)

    // Trigger the input event on page load to set the initial state
    input.dispatchEvent(new Event('input'))
  })

  // Validate inputs
  const validateInput = (input: HTMLInputElement | HTMLTextAreaElement) => {
    if (!isValid(input)) {
      input.classList.add('has-error')
    } else {
      removeErrorStyling(input)
    }
  }

  inputs.forEach((input: HTMLInputElement | HTMLTextAreaElement) => {
    if (input.type === 'text' || input.type === 'email') {
      input.addEventListener('blur', () => {
        if (inputHasValue(input)) validateInput(input)
        else removeErrorStyling(input)
      })
    }
  })
}

export const inputHasValue = (input: HTMLInputElement | HTMLTextAreaElement) => {
  return input.value.trim().length > 0
}

export function toggleInputHasValue(input: HTMLInputElement | HTMLTextAreaElement) {
  input.addEventListener('input', () => {
    if (inputHasValue(input)) {
      input.classList.add('has-value')
    } else {
      removeHasValueStyling(input)
    }
  })
}

export function removeHasValueStyling(input: HTMLInputElement | HTMLTextAreaElement) {
  input.classList.remove('has-value')
}

function removeErrorStyling(input: HTMLInputElement | HTMLTextAreaElement) {
  input.classList.remove('has-error')
}
