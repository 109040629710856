import { isMobile } from '@/utils/is-mobile'
import { smoothScrollTo } from '@/utils/smooth-scroll'

export const Anchor = () => {
  const OFFSET_EXTRA_SPACE = 20
  const EXTRA_SPACE_FOR_ANCHOR = 70
  const header: HTMLElement | null = document.querySelector('[data-component="dfi-header"]')
  const OFFSET_TOP_MOBILE = (header?.offsetHeight || 0) + 72
  const OFFSET_TOP_PC = (header?.offsetHeight || 0) + OFFSET_EXTRA_SPACE
  const headerHeight = header?.offsetHeight || 0
  const anchorItems: NodeListOf<HTMLElement> = document.querySelectorAll('[data-anchor-item]')
  const stickyAnchorMenuElements: NodeListOf<HTMLElement> = document.querySelectorAll(
    '[data-component="dfi-anchor-menu"]'
  )

  /**
   * Checks if the given element is currently in the viewport.
   * @param {HTMLElement} element
   * @return {boolean}
   */
  const isAnchorItemInViewport = (element: HTMLElement): boolean => {
    const rect = element.getBoundingClientRect()
    const isTabActive = Boolean(element?.parentElement?.closest('.dfi-tab__content--active'))

    // Get viewport dimensions, excluding the header height
    const viewportHeight = window.innerHeight
    const viewportWidth = window.innerWidth

    // Calculate the middle point of the viewport, excluding the fixed header
    const viewportMiddleY = (viewportHeight - headerHeight) / 2 + headerHeight
    const viewportMiddleX = viewportWidth / 2

    // Calculate the element's center point
    const elementCenterY = rect.top + rect.height / 2
    const elementCenterX = rect.left + rect.width / 2

    // Determine if the element's center is close to the middle of the viewport
    const isInVerticalMiddle =
      elementCenterY >= viewportMiddleY - rect.height / 2 &&
      elementCenterY <= viewportMiddleY + rect.height / 2
    const isInHorizontalMiddle =
      elementCenterX >= viewportMiddleX - rect.width / 2 &&
      elementCenterX <= viewportMiddleX + rect.width / 2

    return isInVerticalMiddle && isInHorizontalMiddle && isTabActive
  }

  // Highlights the active anchor in the anchor menu list
  const highlightActiveAnchor = (anchor: HTMLElement) => {
    const ulAnchorParent: HTMLElement | null = anchor.closest(
      '[data-component="dfi-anchor-menu-list"]'
    )
    const anchorActiveLi = ulAnchorParent?.querySelector('.dfi-contact-anchor__item--active')

    anchorActiveLi?.classList?.remove('dfi-contact-anchor__item--active')
    anchor?.parentElement?.classList.add('dfi-contact-anchor__item--active')
  }

  const highlightActiveAnchorOnScroll = (anchorItems: NodeListOf<HTMLElement>) => {
    anchorItems.forEach((anchorItem) => {
      if (!isAnchorItemInViewport(anchorItem)) return

      const anchorTarget = anchorItem.getAttribute('data-anchor-item')
      const currentAnchorContent = anchorItem?.closest('[data-component="dfi-tab-content"]')

      if (anchorTarget && currentAnchorContent) {
        const targetElement: HTMLElement | null = currentAnchorContent.querySelector(
          `[data-anchor-item=${anchorTarget}]`
        )

        if (targetElement) {
          if (isMobile) {
            const anchor: HTMLSelectElement | null = currentAnchorContent.querySelector(
              `[data-component="dfi-anchor-select"]`
            )
            if (anchor) {
              const option: HTMLOptionElement | null = anchor.querySelector(
                `option[value="${anchorTarget}"]`
              )

              if (option) {
                option.selected = true
              }
            }
          } else {
            const anchor: HTMLElement | null = document.querySelector(
              `[data-component="dfi-anchor-item"][href="#${anchorTarget}"]`
            )

            if (anchor) {
              highlightActiveAnchor(anchor)
            }
          }
        }
      }
    })
  }

  const makeAnchorMenuSticky = (headerHeight: number, stickyElements: NodeListOf<HTMLElement>) => {
    stickyElements.forEach((stickyElement) => {
      const targetParent = stickyElement?.closest('[data-component="dfi-tab-content"]')

      if (!targetParent) return

      const stickyRect = stickyElement.getBoundingClientRect()
      const targetRect = targetParent.getBoundingClientRect()

      if (!stickyRect || !targetRect) return

      if (isMobile) {
        // if user scroll down and top of the anchor menu is <= the header height - make anchor menu sticky
        if (targetRect.top <= headerHeight) {
          stickyElement.style.position = 'sticky'
          stickyElement.style.top = `${headerHeight}px`
          stickyElement.style.left = '0'
          stickyElement.style.right = '0'
          stickyElement.style.marginLeft = '-16px'
          stickyElement.style.width = '100vw'
          stickyElement.classList.add('max-xl:border-l-transparent', 'max-xl:border-r-transparent')
        } else {
          stickyElement.style.position = 'relative'
          stickyElement.style.top = '0'
          stickyElement.style.marginLeft = '0'
          stickyElement.style.width = '100%'
          stickyElement.classList.remove(
            'max-xl:border-l-transparent',
            'max-xl:border-r-transparent'
          )
        }
      } else {
        // if user scroll down and top of the anchor menu is <= the header height - make anchor menu sticky
        if (targetRect.top <= headerHeight + EXTRA_SPACE_FOR_ANCHOR) {
          stickyElement.style.position = 'sticky'
          stickyElement.style.top = `${headerHeight + EXTRA_SPACE_FOR_ANCHOR}px`
        } else {
          stickyElement.style.position = 'relative'
          stickyElement.style.top = '0'
        }
      }
    })
  }

  const makeSmoothScrollAfterAnchor = (href: string | undefined, topOffset: number) => {
    if (href) {
      const target: HTMLHeadElement | null = document.querySelector(`[data-anchor-item=${href}]`)

      if (target) {
        const top = getElementOffsetTop(target) - topOffset

        smoothScrollTo(top, 700)
      }
    }
  }

  const getElementOffsetTop = (element) => {
    // offsetTop: Returns the offset relative to the nearest positioned (i.e., relative, absolute, or fixed) ancestor.
    // offsetParent: Returns the closest positioned ancestor element.
    // If the element has no positioned ancestors, it returns the body or null for certain elements like the root.

    let offsetTop = 0

    while (element) {
      offsetTop += element.offsetTop
      element = element.offsetParent
    }

    return offsetTop
  }

  const anchorHandlerDesktop = (OFFSET_TOP: number) => {
    const anchors: NodeListOf<HTMLElement> = document.querySelectorAll(
      '[data-component="dfi-anchor-item"]'
    )

    if (anchors.length) {
      anchors.forEach((anchor) => {
        anchor.addEventListener('click', (e) => {
          e.preventDefault()

          const targetHref = anchor.getAttribute('href')?.substring(1)

          makeSmoothScrollAfterAnchor(targetHref, OFFSET_TOP)
        })
      })
    }
  }

  const anchorHandlerMobile = (OFFSET_TOP: number) => {
    const selects: NodeListOf<HTMLSelectElement> = document.querySelectorAll(
      '[data-component="dfi-anchor-menu"] [data-component="dfi-anchor-select"]'
    )

    selects.forEach((select) => {
      select.addEventListener('change', (e) => {
        const eventTarget = e.target as HTMLSelectElement
        const targetHref = eventTarget.value

        select.value = ''
        makeSmoothScrollAfterAnchor(targetHref, OFFSET_TOP)
      })
    })
  }

  window.addEventListener('scroll', () => {
    makeAnchorMenuSticky(headerHeight, stickyAnchorMenuElements)
    highlightActiveAnchorOnScroll(anchorItems)
  })

  if (isMobile) {
    anchorHandlerMobile(OFFSET_TOP_MOBILE)
  } else {
    anchorHandlerDesktop(OFFSET_TOP_PC)
  }
}
