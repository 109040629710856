import { removeHasValueStyling } from '@/assets/scripts/components/input'

export const ClearSearch = () => {
  const HIDDEN_CLEAR_BUTTON_CLASSES = ['invisible', 'opacity-0']
  const VISIBLE_CLEAR_BUTTON_CLASSES = ['visible', 'opacity-100']

  const searchClearButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll(
    '[data-component="dfi-search-clear-button"]'
  )
  const searchInputs: NodeListOf<HTMLInputElement> = document.querySelectorAll(
    '[data-component="dfi-search"]'
  )

  const searchInputHandler = () => {
    searchInputs.forEach((searchInput) => {
      // get clear button based on parent node
      const searchClearButton: HTMLButtonElement | null | undefined = searchInput.parentElement
        ?.closest('form')
        ?.querySelector('[data-component="dfi-search-clear-button"]')

      searchInput.addEventListener('input', () => {
        if (searchInput.value) {
          searchClearButton?.classList?.add(...VISIBLE_CLEAR_BUTTON_CLASSES)
          searchClearButton?.classList?.remove(...HIDDEN_CLEAR_BUTTON_CLASSES)
        } else {
          searchClearButton?.classList?.add(...HIDDEN_CLEAR_BUTTON_CLASSES)
          searchClearButton?.classList?.remove(...VISIBLE_CLEAR_BUTTON_CLASSES)
        }
      })
    })
  }

  const clearButtonClickHandler = () => {
    // get input based on parent node
    searchClearButtons.forEach((searchClearButton) => {
      const searchInput: HTMLInputElement | undefined | null = searchClearButton.parentElement
        ?.closest('form')
        ?.querySelector('[data-component="dfi-search"]')

      if (!searchInput?.value) {
        searchClearButton?.classList?.add(...HIDDEN_CLEAR_BUTTON_CLASSES)
        searchClearButton?.classList?.remove(...VISIBLE_CLEAR_BUTTON_CLASSES)
      }

      searchClearButton.addEventListener('click', () => {
        if (searchInput) {
          searchInput.value = ''
          searchInput.focus()
          removeHasValueStyling(searchInput)

          searchClearButton?.classList?.add(...HIDDEN_CLEAR_BUTTON_CLASSES)
          searchClearButton?.classList?.remove(...VISIBLE_CLEAR_BUTTON_CLASSES)
        }
      })
    })
  }

  clearButtonClickHandler()
  searchInputHandler()
}
