import { copyToClipboard } from '@/utils/copy-to-clipboard'
import { isMobile } from '@/utils/is-mobile'

// singleton pattern to track all copied elements and avoid multiple copies
class ClipboardTracker {
  private static instance: ClipboardTracker
  private trackedElements: Set<HTMLElement>

  private constructor() {
    this.trackedElements = new Set<HTMLElement>()
  }

  public static getInstance(): ClipboardTracker {
    if (!ClipboardTracker.instance) {
      ClipboardTracker.instance = new ClipboardTracker()
    }
    return ClipboardTracker.instance
  }

  public addCopyListener(element: HTMLElement) {
    if (this.trackedElements.has(element)) return

    element.addEventListener('click', (e) => {
      e.preventDefault()

      copyToClipboard(element)

      element.classList.add('copied')

      setTimeout(() => {
        element.classList.remove('copied')
      }, 2000)
    })

    this.trackedElements.add(element)
  }
}

export const CopyToClipboard = () => {
  if (isMobile) return

  const copyToClipboardElements: NodeListOf<HTMLElement> = document.querySelectorAll(
    '[data-component="dfi-clipboard"]'
  )

  const tracker = ClipboardTracker.getInstance()

  copyToClipboardElements.forEach((element) => {
    tracker.addCopyListener(element)
  })
}
