/** @type {import('tailwindcss').Config} */
export default {
  content: [
    './src/assets/scripts/components/**/*.ts',
    './src/stories/**/*.stories.mdx',
    './src/stories/**/*.stories.@(js|jsx|ts|tsx)',
    './src/components/*.vue',
    '../Views/**/*.cshtml'
  ],
  darkMode: 'media',
  theme: {
    fontFamily: {
      sans: '"DM Sans", Helvetica, Arial, sans-serif', // Set as default
      firm: ['"Firm Sans"', 'Helvetica', 'Arial', 'sans-serif'] // Custom utility for Firm Sans
    },
    fontSize: {
      12: [
        '0.75rem',
        {
          lineHeight: '1.3'
        }
      ],
      14: [
        '0.875rem',
        {
          lineHeight: '1.3'
        }
      ],
      16: [
        '1rem',
        {
          lineHeight: '1.4'
        }
      ],
      18: [
        '1.125rem',
        {
          lineHeight: '1.4'
        }
      ],
      20: [
        '1.25rem',
        {
          lineHeight: '1.1'
        }
      ],
      30: [
        '1.875rem',
        {
          lineHeight: '1.1'
        }
      ],
      42: [
        '2.625rem',
        {
          lineHeight: '1.1'
        }
      ],
      52: [
        '3.25rem',
        {
          lineHeight: '1'
        }
      ],
      70: [
        '4.375rem',
        {
          lineHeight: '1'
        }
      ]
    },
    fontWeight: {
      normal: '400',
      bold: '700'
    },
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      white: '#FFFFFF',
      neutral: {
        50: '#F1F2F4',
        100: '#E2E4E8',
        300: '#A5AEC0',
        400: '#8793AB',
        500: '#687896',
        600: '#536078',
        700: '#3F485A',
        800: '#2A303C'
      },
      blue: {
        40: '#9AB5E766', // Secondary button - 40% opacity
        100: '#F2F5FD',
        200: '#BFD0EF',
        800: '#173150', // Brand color
        900: '#091F38'
      },
      orange: {
        100: '#FFF1E9',
        400: '#D77151'
      },
      green: {
        100: '#F2F9D5',
        200: '#ECF7B8',
        400: '#CDEF45'
      },
      beige: {
        100: '#FFFDF3',
        200: '#F0EFE9'
      },
      functional: {
        success: '#35AB0C',
        warning: '#EB9007',
        error: '#DC2115',
        link: '#4670C1'
      },
      primary: 'var(--color-primary)',
      secondary: 'var(--color-secondary)',
      tertiary: 'var(--color-tertiary)',
      quaternary: 'rgb(var(--color-quaternary))',
      'site-bg': 'var(--color-site-bg)'
    },
    spacing: {
      0: '0px',
      1: '1px',
      2: '2px',
      4: '0.25rem',
      8: '0.5rem',
      12: '0.75rem',
      16: '1rem',
      24: '1.5rem',
      32: '2rem',
      40: '2.5rem',
      48: '3rem',
      64: '4rem',
      80: '5rem',
      96: '6rem',
      106: '6.625rem',
      120: '7.5rem',
      200: '12.5rem',
      'content-block-gap': '6.5vw'
    },
    borderRadius: {
      none: '0',
      DEFAULT: '1.125rem', // 18px
      xs: '0.125rem', // 2px
      sm: '0.375rem', // 6px
      xxl: '6.25rem', // 100px
      full: '9999px' // Full circle
    },
    transitionDuration: {
      DEFAULT: '250ms',
      mobileMenu: '350ms', // Mobile only
      megaDropdown: '350ms', // Desktop only
      heroBorder: '850ms'
    },
    transitionTimingFunction: {
      DEFAULT: 'ease-out',
      'ease-in-out': 'ease-in-out'
    },
    extend: {
      maxWidth: {
        page: '1512px',
        medium: '1176px',
        small: '936px',
        'text-content': '700px'
      },
      gridTemplateRows: {
        'auto-1fr': 'auto 1fr'
      },
      gridTemplateColumns: {
        'link-cards': 'repeat(auto-fill, minmax(400px, 1fr))',
        'contact-cards': 'repeat(auto-fill, minmax(330px, 1fr))',
        'facts-grid': 'repeat(auto-fill, minmax(330px, 1fr))',
        'tabs-container': '1fr 200px',
        'overview-grid': 'repeat(auto-fill, minmax(330px, 1fr))',
        'map-grid': 'minmax(0,700px) 2fr',
        'sub-hero-signup-grid': 'minmax(400px, 33.33%) 1fr 1fr 1fr',
        'highlight-component-grid': '2fr 1fr'
      },
      zIndex: {
        1: '1'
      },
      aspectRatio: {
        '5/6': '5 / 6',
        '5/3': '5 / 3'
      },
      screens: {
        xs: '460px'
      }
    }
  },
  plugins: []
}
