import { isMobile } from '@/utils/is-mobile.ts'

export const Header = () => {
  const dfiHeader = document.querySelector("[data-component='dfi-header']") as HTMLElement | null
  const dfiMobileMenuOpenButton = document.querySelector(
    "[data-component='dfi-open-mobile-menu']"
  ) as HTMLElement | null
  const dfiMobileGoBackButton = document.querySelectorAll("[data-component='dfi-mobile-go-back']")
  const dfiMobileMenuCloseButton = document.querySelector(
    "[data-component='dfi-close-mobile-menu']"
  ) as HTMLElement | null
  const dfiMobileMenu = document.querySelector("[data-component='dfi-menus']") as HTMLElement | null
  const dfiPrimaryMenu = document.querySelector(
    "[data-component='dfi-primary-menu']"
  ) as HTMLElement | null
  const dfiPrimaryMenuItems = document.querySelectorAll(
    "[data-component='dfi-primary-menu-items']"
  ) as NodeListOf<HTMLElement> // Explicitly typing NodeList as HTMLElement

  const transitionClasses = ['xl:transition-[max-height]', 'xl:duration-megaDropdown']
  const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0
  }
  let currentMenuDropdown: HTMLElement | null = null
  const DROPDOWN_IS_VISIBLE = 'dropdown-is-visible'
  const SCROLL_ANIMATION_START_POS: number = 37

  // Scroll lock for iOS: Add classes to the <html> element
  const SCROLL_LOCK_CLASSES = ['max-xl:fixed', 'max-xl:overflow-hidden']
  // Stores the current scroll position of the page to prevent unintended scrolling when the mobile menu is opened.
  // This is used to restore the scroll position when the menu is closed, ensuring the user remains at the same spot.
  let scrollTop: number = 0

  // If the primary menu is a subsite menu, we don't want to show the mega dropdowns on hover and we want to be able to click the links
  const isSubsite = dfiPrimaryMenuItems[0]?.classList.contains('dfi-is-subsite-menu')

  /* Clicking primary menu link on mobile shows mega dropdown as an overlay */
  const handleMenuClick = (item) => {
    const menuDropdown = item.querySelector(
      "div[data-component='dfi-mega-dropdown']"
    ) as HTMLElement | null

    if (menuDropdown && !menuDropdown.classList.contains(DROPDOWN_IS_VISIBLE)) {
      // Open the dropdown
      menuDropdown.classList.add(DROPDOWN_IS_VISIBLE)
      currentMenuDropdown = menuDropdown
    }
  }

  // Close level 2 (mega dropdown / overlay) when user clicks back button in mobile menu
  dfiMobileGoBackButton.forEach((goBackButton) => {
    goBackButton.addEventListener('click', () => {
      const visibleDropdowns = document.querySelectorAll(
        `div[data-component='dfi-mega-dropdown'].${DROPDOWN_IS_VISIBLE}`
      ) as NodeListOf<HTMLElement>

      // Iterate over each visible dropdown and remove the DROPDOWN_IS_VISIBLE class
      visibleDropdowns.forEach((dropdown) => {
        dropdown.classList.remove(DROPDOWN_IS_VISIBLE)
      })

      // Reset the currentMenuDropdown reference
      currentMenuDropdown = null
    })
  })

  // Handle scroll events
  const handleScroll = () => {
    if (window.scrollY > SCROLL_ANIMATION_START_POS) {
      dfiHeader?.classList.add('dfi-header-scroll')
    } else {
      dfiHeader?.classList.remove('dfi-header-scroll')
    }
  }

  // Flag to check if the scroll event listener is already added
  let isScrollListenerAdded = false

  // Add scroll event listener if not already added
  const addScrollListener = () => {
    if (!isScrollListenerAdded && dfiHeader) {
      window.addEventListener('scroll', handleScroll, { passive: true })
      isScrollListenerAdded = true // Set the flag to true after adding
      handleScroll() // Call it once to set initial state
    }
  }

  // Open and close mobile menu functions
  const openMobileMenu = () => {
    scrollTop = window.scrollY

    // Scroll lock for iOS: Add classes to the <html> element
    setTimeout(() => {
      document.documentElement.classList.add(...SCROLL_LOCK_CLASSES)
    }, 350) // Tailwind mobileMenu (transitionDuration)

    dfiHeader?.classList.add('mobile-menu-is-open')
    dfiMobileMenuOpenButton?.classList.add('is-active')
    dfiMobileMenuOpenButton?.setAttribute('aria-expanded', 'true')
    dfiMobileMenu?.classList.remove('max-xl:opacity-0', 'max-xl:-translate-x-full')

    document.documentElement.scroll(0, scrollTop)
  }

  const closeMobileMenu = () => {
    // Remove scroll lock from <html> element
    document.documentElement.classList.remove(...SCROLL_LOCK_CLASSES)

    dfiHeader?.classList.remove('mobile-menu-is-open')
    dfiMobileMenuCloseButton?.classList.remove('is-active')
    dfiMobileMenuCloseButton?.setAttribute('aria-expanded', 'false')
    dfiMobileMenu?.classList.add('max-xl:opacity-0', 'max-xl:-translate-x-full')

    window.scrollTo(0, scrollTop)

    // Remove 'dropdown-is-visible' class from all mega dropdowns to reset state back to root level
    const megaDropdowns = document.querySelectorAll(
      "div[data-component='dfi-mega-dropdown']"
    ) as NodeListOf<HTMLElement>
    megaDropdowns.forEach((dropdown) => {
      dropdown.classList.remove(DROPDOWN_IS_VISIBLE)
    })
  }

  /* Desktop only - will animate the height of the mega dropdown */
  const toggleMenuVisibility = (dropdown, show: boolean = true) => {
    if (dropdown && show) {
      dropdown.style.maxHeight = dropdown.scrollHeight + 'px'
      dfiHeader?.classList.add(DROPDOWN_IS_VISIBLE)
    } else if (dropdown) {
      dropdown.style.maxHeight = '0'
      dfiHeader?.classList.remove(DROPDOWN_IS_VISIBLE)
    }
  }

  // Function to handle mouseenter event
  const handleMouseEnter = (item, menuDropdown) => {
    item.addEventListener('mouseenter', () => {
      // Disable link if we are on a touch device
      if (isTouchDevice()) {
        const link = item.querySelector('a')
        if (link) {
          link.addEventListener('click', (e: Event) => {
            e.preventDefault()
          })
        }
      }

      if (menuDropdown) {
        // Show hovered menu item dropdown
        if (!currentMenuDropdown) {
          menuDropdown.classList.add(...transitionClasses)
        }
        toggleMenuVisibility(menuDropdown)

        // If there is a current dropdown open that is not the one hovered over, we hide it
        if (currentMenuDropdown && currentMenuDropdown !== menuDropdown) {
          currentMenuDropdown.classList.remove(...transitionClasses)
          menuDropdown.classList.remove(...transitionClasses)
          toggleMenuVisibility(currentMenuDropdown, false)
          toggleMenuVisibility(menuDropdown, true)
        }
        currentMenuDropdown = menuDropdown
      }
    })
  }

  // Function to handle mouseleave event
  const handleMouseLeave = (dfiPrimaryMenu) => {
    dfiPrimaryMenu?.addEventListener('mouseleave', () => {
      if (currentMenuDropdown) {
        const menuDropdown = currentMenuDropdown
        menuDropdown.classList.add(...transitionClasses)
        toggleMenuVisibility(menuDropdown, false)

        setTimeout(() => {
          menuDropdown.classList.remove(...transitionClasses)
        }, 600)

        currentMenuDropdown = null
      }
    })
  }

  const handleMenuKeyDown = (item: HTMLElement, event: KeyboardEvent) => {
    const menuDropdown = item.querySelector("div[data-component='dfi-mega-dropdown']")
    const primaryLinks = item.querySelector('a') as HTMLElement // Target the link inside the li

    switch (event.key) {
      case ' ':
        event.preventDefault() // Prevent page scroll

        if (dfiHeader?.classList.contains(DROPDOWN_IS_VISIBLE)) {
          // If the menu is open, close it
          toggleMenuVisibility(menuDropdown, false)
          primaryLinks?.focus() // Focus the primary link when dropdown closes
        } else {
          // If the menu is closed, open it
          toggleMenuVisibility(menuDropdown, true)
        }
        break

      case 'Escape':
        // Close the dropdown and return focus to the primary menu item
        toggleMenuVisibility(menuDropdown, false)
        primaryLinks?.focus() // Focus the primary link when dropdown closes
        break
    }
  }

  // Call the function to add the scroll listener
  addScrollListener()

  /* Open and close mobile menu */
  if (dfiMobileMenuOpenButton && dfiMobileMenuCloseButton && dfiMobileMenu) {
    dfiMobileMenuOpenButton.addEventListener('click', openMobileMenu)
    dfiMobileMenuCloseButton.addEventListener('click', closeMobileMenu)
  }

  /* Show and hide mega dropdown when clicking or hovering the primary nav items (not for subsites) */
  if (dfiPrimaryMenuItems && dfiPrimaryMenuItems.length > 0 && !isSubsite) {
    dfiPrimaryMenuItems.forEach((item) => {
      const menuDropdown = item.querySelector(
        "div[data-component='dfi-mega-dropdown']"
      ) as HTMLElement | null

      /* MOBILE */
      if (isMobile) {
        menuDropdown?.classList.add('opacity-0', 'pointer-events-none')

        const primaryLink = item.querySelector('a') as HTMLElement
        if (primaryLink) {
          primaryLink.addEventListener('click', (e: Event) => {
            e.preventDefault() // Prevents clicking on primary links on mobile - should just open dropdown as an overlay
            handleMenuClick(item)
          })
        }

        /* DESKTOP */
      } else {
        // Initially hide mega dropdowns on desktop
        toggleMenuVisibility(menuDropdown, false)
        handleMouseEnter(item, menuDropdown)
        handleMouseLeave(dfiPrimaryMenu)
      }

      // Keyboard navigation
      item.addEventListener('keydown', (e: KeyboardEvent) => handleMenuKeyDown(item, e))
    })
  }

  // DEBUGGING Mobile : test which div is currently being scrolled by the user
  /*function findScrollingDiv() {
    const containers = document.querySelectorAll('div')
    // Iterate through each scrollable container and check if it is currently scrolling
    containers.forEach((container) => {
      if (
        container.scrollTop > 0 &&
        container.scrollTop + container.clientHeight < container.scrollHeight
      ) {
        const dataComponent = container.getAttribute('data-component')
        console.log(`'${dataComponent}' is scrolling`)
      }
    })
  }

  document.addEventListener('scroll', findScrollingDiv, true)*/
}
