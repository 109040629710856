import { createApp } from 'vue'
import LoginModal from '../../../components/LoginModal.vue'

export const LoadLoginModal = () => {
  const appElementLoginModal = document.querySelector('[data-vue-app="login-modal-component"]')

  if (!appElementLoginModal) {
    console.error('Login modal component not found in the DOM')
    return
  }

  // Get the button label from the data attribute in the HTML
  const buttonLabel = appElementLoginModal.getAttribute('data-button-label') || 'Default Label'

  // Create and mount the Vue app to the selected element
  createApp({
    components: { LoginModal },
    data() {
      return {
        buttonLabel // Pass the button label as data to the component
      }
    },
    template: appElementLoginModal.innerHTML // Use inner HTML to capture slotted content
  }).mount(appElementLoginModal)
}
