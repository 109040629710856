<template>
  <button
    ref="loginTriggerButton"
    class="flex items-center gap-8 underline decoration-site-bg transition hover:decoration-blue-900 max-xl:w-full max-xl:border-t max-xl:border-t-neutral-100 max-xl:px-16 max-xl:py-24 xl:ml-24 xl:border-l xl:border-l-neutral-300 xl:py-1 xl:pl-24 xl:decoration-beige-200"
    type="button"
    @click="showModal"
  >
    <svg aria-hidden="true" class="dfi-icon">
      <use :href="`${svgAssetPath}#user`"></use>
    </svg>
    {{ buttonLabel }}
  </button>
  <VueModal ref="modal" :back-button-text="backButtonText">
    <slot></slot>
  </VueModal>
</template>

<script lang="ts" setup>
import { onBeforeMount, ref } from 'vue'
import VueModal from '@/components/VueModal.vue'
import { getAssetsPath } from '@/utils/asset-path.ts'

type PropTypes = {
  buttonLabel: string
  backButtonText: string
}

withDefaults(defineProps<PropTypes>(), {
  buttonLabel: 'Login',
  backButtonText: 'Tilbage'
})

const svgAssetPath = ref<string | null>(null)
const modal = ref<InstanceType<typeof VueModal>>()
const loginTriggerButton = ref<HTMLButtonElement>()

const showModal = () => {
  if (modal.value) {
    // Pass the login button reference to the modal component
    modal.value.open(loginTriggerButton.value)
  }
}

onBeforeMount(() => {
  svgAssetPath.value = getAssetsPath()
})
</script>
