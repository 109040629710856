import { StoryFn } from '@storybook/vue3'

type Args = {
  dueDateLabel: string
  dueDate: string
  isExpiredDueDate: boolean
  style?: string
}

const data: Args = {
  dueDateLabel: 'Tilmeldingsfrist',
  dueDate: '1. Juli',
  isExpiredDueDate: false,
  style: 'Blue'
}

export default {
  title: 'Components/Tag',
  argTypes: {
    style: {
      control: 'radio',
      options: ['Blue', 'White']
    }
  },
  args: data
}

const Template = (args) => {
  // Determine the style class
  const styleClass = args.style === 'Blue' ? 'bg-blue-100' : 'bg-white'

  return {
    template: `  
      <div class="inline-block uppercase subtitle-12 rounded-xxl px-16 py-8 ${styleClass}">
        ${args.dueDateLabel}${args.dueDate ? `:` : ''}
        ${args.dueDate ? `<span class="inline-block ml-4">${args.dueDate}</span>` : ''}
      </div>
    `
  }
}

export const Tag = Template.bind({})

export const TagWhite: StoryFn = Template.bind({})
TagWhite.args = {
  style: 'White'
}

export const IsExpired: StoryFn = Template.bind({})
IsExpired.args = {
  isExpiredDueDate: true,
  dueDate: '',
  dueDateLabel: 'Lukket for tilmelding'
}
