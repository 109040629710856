import { createApp } from 'vue'
import ContactModal from '../../../components/ContactModal.vue'

export const LoadContactModal = () => {
  const appElements = document.querySelectorAll('[data-vue-app="contact-modal-component"]')

  appElements.forEach((appElement) => {
    // Create and mount the Vue app to the selected element
    createApp({
      components: { ContactModal },
      template: appElement.innerHTML, // Use inner HTML to capture slotted content
      data() {
        return {
          buttonLabel: 'Ansvarsområder'
        }
      }
    }).mount(appElement)
  })
}
