<!--
  Modal animation

  The modal backdrop features a blur effect and fades in with increasing opacity.
  DESKTOP : The modal itself slides in from right to left, and fades in with increasing opacity.
  MOBILE: the modal itself slides in from left to right, and fades in with increasing opacity.
  -->

<!--
  Scroll behavior

  The content area can be scrolled if needed
  MOBILE: when the user scrolls the back and close button container will have a border added, but not initially
-->

<!--
  On hover, the close button icon enlarges.
  DESKTOP: the close button is positioned top right and will just be fixed
  MOBILE: A back button has been added as well to close the modal
-->

<template>
  <teleport to="body">
    <!-- <Teleport> fixes layout issues by breaking out of the nested DOM. -->
    <div>
      <Transition>
        <div
          v-show="isModalOpen"
          ref="modalEl"
          class="fixed left-0 right-0 top-0 z-20 flex h-dvh items-end justify-end xl:left-auto xl:w-1/2 xl:max-w-[600px]"
        >
          <!-- Modal content -->
          <div class="modal-transition z-20 flex h-full w-full bg-site-bg">
            <!-- Modal close buttons -->
            <div
              :class="{ 'max-xl:border-b max-xl:border-blue-800': isScrolled }"
              class="absolute right-0 top-0 z-30 flex items-center justify-between max-xl:left-0 max-xl:bg-site-bg"
            >
              <!-- Mobile back link -->
              <div v-if="backButton" class="p-16 xl:hidden">
                <button
                  class="group/link-with-arrow flex flex-row-reverse items-center justify-end gap-8 text-blue-900 transition"
                  @click="onCloseModal()"
                >
                  {{ backButtonText }}
                  <svg
                    aria-hidden="true"
                    class="dfi-icon-12 rotate-180 transition group-hover/link-with-arrow:-translate-x-1"
                  >
                    <use :href="`${svgAssetPath}#arrow-right`"></use>
                  </svg>
                </button>
              </div>

              <!-- Close button -->
              <button
                aria-label="Luk"
                class="group/modal-close-button ml-auto p-16 xl:mr-16"
                type="button"
                @click="onCloseModal()"
              >
                <svg
                  aria-hidden="true"
                  class="dfi-icon-24 close-button-icon"
                  fill="none"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    class="origin-center transition-all group-hover/modal-close-button:scale-125"
                    d="M10.5352 13.4648L3.46409 20.5359"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-width="1"
                    vector-effect="non-scaling-stroke"
                  />
                  <path
                    class="origin-center transition-all group-hover/modal-close-button:scale-125"
                    d="M20.4854 20.4844L3.51479 3.51381"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-width="1"
                    vector-effect="non-scaling-stroke"
                  />
                  <path
                    class="origin-center transition-all group-hover/modal-close-button:scale-125"
                    d="M20.5352 3.46484L13.4641 10.5359"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-width="1"
                    vector-effect="non-scaling-stroke"
                  />
                </svg>
              </button>
            </div>

            <!-- Scrollable modal content area -->
            <div
              class="h-full grow overflow-y-auto overscroll-contain p-16 pb-48 transition max-xl:pt-80 xl:content-center xl:px-64 xl:py-64"
              @scroll="onScroll"
            >
              <h2 v-if="$slots.title" class="title-30 xl:title-42">
                <slot name="title"></slot>
              </h2>
              <slot />
            </div>
          </div>
        </div>
      </Transition>

      <!-- Backdrop needs to be outside vue transition or the blur will be applied with a delay -->
      <div
        :class="[backdropClasses]"
        class="dfi-blurry-overlay fixed left-0 right-0 top-0 z-10 h-dvh"
        @click="onCloseModal()"
      />
    </div>
  </teleport>
</template>

<script lang="ts" setup>
import { onBeforeMount, onMounted, onUnmounted, ref } from 'vue'
import { useScrollLock } from '@vueuse/core'
import { getAssetsPath } from '@/utils/asset-path.ts'

type PropTypes = {
  backButton?: boolean
  backButtonText?: string
}

withDefaults(defineProps<PropTypes>(), {
  backButton: true,
  backButtonText: 'Tilbage'
})

const bodyRef = ref(document.body)
const isScrollLocked = useScrollLock(bodyRef)
const modalEl = ref()
const isModalOpen = ref(false)
const hiddenBackdropClasses = 'invisible opacity-0'
const backdropClasses = ref(hiddenBackdropClasses)
const isScrolled = ref(false)
const isWindowScrolled = ref(false)
const svgAssetPath = ref<string | null>(null)

// Desktop : When modal is open the content is moved 32px to the left
const modalOpenClasses = ['xl:!-ml-32']

// Initially add transition to body
bodyRef.value.classList.add('transition-[margin]')

const loginTriggerButton = ref<HTMLElement | null>(null)

const onOpenModal = (element?: HTMLElement) => {
  isModalOpen.value = true
  isScrollLocked.value = true
  backdropClasses.value = ''
  bodyRef.value.classList.add(...modalOpenClasses)

  // Save the reference to the login button
  if (element) {
    loginTriggerButton.value = element
  }
}
const onCloseModal = () => {
  isModalOpen.value = false
  isScrollLocked.value = false
  backdropClasses.value = hiddenBackdropClasses
  bodyRef.value.classList.remove(...modalOpenClasses)

  // Blur login button to prevent :focus-visible styles when user closes the modal ny hitting "ESC" key
  if (loginTriggerButton.value) {
    loginTriggerButton.value.blur()
  }
}

// Handle scroll and detect if modal content is scrolled
const onScroll = (event: Event) => {
  const target = event.target as HTMLElement
  isScrolled.value = target.scrollTop > 0
}

// Handle scroll and detect if the window is scrolled
const onWindowScroll = () => {
  isWindowScrolled.value = window.scrollY > 0
}

const onEscKey = (event: KeyboardEvent) => {
  if (event.key === 'Escape') onCloseModal()
}

// Add event listeners
onBeforeMount(() => {
  svgAssetPath.value = getAssetsPath()
})

onMounted(() => {
  window.addEventListener('scroll', onWindowScroll)
  document.addEventListener('keydown', onEscKey)
})

// Remove event listeners
onUnmounted(() => {
  window.removeEventListener('scroll', onWindowScroll)
  document.removeEventListener('keydown', onEscKey)
})

defineExpose({
  open: onOpenModal,
  close: onCloseModal,
  modalEl
})
</script>

<style lang="postcss" scoped>
.v-enter-active,
.v-leave-active {
  @apply transition max-xl:duration-mobileMenu;
}

.v-enter-from,
.v-leave-to {
  @apply opacity-0;
}

.v-enter-active .modal-transition,
.v-leave-active .modal-transition {
  @apply transition max-xl:duration-mobileMenu;
}

.v-leave-to .modal-transition,
.v-enter-from .modal-transition {
  @apply max-xl:-translate-x-full xl:translate-x-full;
}
</style>
