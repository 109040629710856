export const HeroScrollAnimation = () => {
  const borderElement = document.querySelector('.hero-border-animation') as HTMLElement | null
  const dfiHeroHighlightBox = document.querySelector(
    "[data-component='dfi-hero-highlight-box']"
  ) as HTMLElement | null

  const handleScroll = () => {
    if (borderElement) {
      // Get the current scroll position
      const scrollTop = window.scrollY || document.documentElement.scrollTop
      const viewportHeight = window.innerHeight

      const speedAnimateBgColor = 0.2 // Adjust this value to make the highlight box background-color transition faster
      const speedUpBorderAnimation = 2 // Adjust this value to make the border move faster
      const speedUpBoxAnimation = 3 // Adjust this value to make the highlight box move faster

      // Calculate the scroll progress as a percentage relative to the viewport height
      const scrollProgress = Math.min(scrollTop / viewportHeight, 1) // Ensure it doesn't exceed 1

      // Calculate the new height based on scroll progress
      const newHeight = scrollProgress * 100 * speedUpBorderAnimation

      // Set the new height using a CSS variable
      borderElement.style.setProperty('--scroll-position', `${newHeight}px`)

      // Calculate marginTop values based on scroll progress
      const maxMarginTopVH = 8 // maximum margin-top in vh
      const maxMarginTopPX = 130 // maximum margin-top for xl screens in px
      const marginTopValueVH = Math.min(
        maxMarginTopVH,
        scrollProgress * maxMarginTopVH * speedUpBoxAnimation
      ) // MOBILE - Gradually move up to 8vh

      const marginTopValuePX = Math.min(
        maxMarginTopPX,
        scrollProgress * maxMarginTopPX * speedUpBoxAnimation
      ) // DESKTOP - Gradually move up to 130px

      if (dfiHeroHighlightBox) {
        // MOBILE Adjust the margin-top based on scroll progress (using vh)
        dfiHeroHighlightBox.style.marginTop = `-${marginTopValueVH}vh`

        // DESKTOP Adjust the margin-top based on scroll progress (using px)
        if (window.innerWidth >= 1280) {
          dfiHeroHighlightBox.style.marginTop = `-${marginTopValuePX}px`
        }

        // Change background color based on scroll progress
        if (scrollProgress >= speedAnimateBgColor) {
          dfiHeroHighlightBox.classList.remove('bg-secondary')
          dfiHeroHighlightBox.classList.add('bg-primary')
        } else {
          dfiHeroHighlightBox.classList.remove('bg-primary')
          dfiHeroHighlightBox.classList.add('bg-secondary')
        }
      }
    }
  }

  // Flag to check if the scroll event listener is already added
  let isScrollListenerAdded = false

  // Add scroll event listener if not already added
  const addScrollListener = () => {
    if (!isScrollListenerAdded) {
      window.addEventListener('scroll', handleScroll, { passive: true })
      isScrollListenerAdded = true // Set the flag to true after adding
      handleScroll() // Call it once to set initial state
    }
  }

  // Call the function to add the scroll listener
  addScrollListener()

  // Resize Event Handling
  window.addEventListener('resize', handleScroll)
}
