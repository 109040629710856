import { SearchData, SearchItem } from '@/stories/components/search/search-model'
import { isMobile } from '@/utils/is-mobile'
import { SearchItem as SearchItemHtml } from '@/stories/components/search/search-item.stories'
import FetchWrapper from '@/utils/api.ts'
import { playLoader, stopLoader } from '@/assets/scripts/components/loader.ts'

export const Search = () => {
  const pageSize = isMobile ? 12 : 16

  const pageSizeInputs: NodeListOf<HTMLInputElement> = document.querySelectorAll(
    '[data-component="dfi-page-size-input"]'
  )
  const loadMoreButton: HTMLButtonElement | null = document.querySelector(
    '[data-component="dfi-search-load-button"]'
  )
  const searchLoadLabel: HTMLElement | null = document.querySelector(
    '[data-component="dfi-search-load-label"]'
  )
  const searchResultsWrapper = document.querySelector('[data-component="dfi-search-results"]')
  const searchEmpty = document.querySelector('[data-component="dfi-search-empty"]')

  const hideLoadMoreButton = () => {
    if (loadMoreButton) {
      loadMoreButton.classList.add('!hidden')
    }
  }

  const updateLoadMoreButtonApiUrl = (url: string) => {
    if (loadMoreButton && url) {
      loadMoreButton.setAttribute('data-api-url', url)
    }
  }

  const updateInfoAfterLoadMoreAction = (response: SearchData) => {
    if (!response.loadMoreApiUrl) {
      hideLoadMoreButton()
    }

    if (searchLoadLabel) {
      searchLoadLabel.innerText = response.loadMoreResultsLabel
    }
    updateLoadMoreButtonApiUrl(response.loadMoreApiUrl)
  }

  const rendererListItem = (item: SearchItem) => {
    return SearchItemHtml(item).template
  }

  const updateSearchResultHtml = (response: SearchData, isNewSearch = false) => {
    const searchList: HTMLElement | null = document.querySelector(
      '[data-component="dfi-search-list"]'
    )
    const newItems = response?.items.map(rendererListItem).join('')

    // push new items to UL
    if (searchList) {
      if (isNewSearch) {
        searchList.innerHTML = newItems
      } else {
        searchList.insertAdjacentHTML('beforeend', newItems)
      }
    }

    // remove hidden after emty results and add for empty search results
    searchResultsWrapper?.classList.remove('hidden')
    searchEmpty?.classList.add('hidden')
    // Update info in load more section
    updateInfoAfterLoadMoreAction(response)
  }

  const load = async (url: string): Promise<SearchData> => {
    const api = new FetchWrapper()

    return api.get(url)
  }

  const loadMoreClickHandler = async () => {
    if (loadMoreButton) {
      loadMoreButton.addEventListener('click', async (e: Event) => {
        e.preventDefault()

        try {
          const url = loadMoreButton.getAttribute('data-api-url')

          if (url) {
            playLoader()
            const loadResponse = await load(url)

            updateSearchResultHtml(loadResponse)
          }
        } catch (error) {
          console.error(error)
        } finally {
          stopLoader()
        }
      })
    }
  }

  if (!loadMoreButton?.getAttribute('data-api-url')) {
    hideLoadMoreButton()
  }

  // set pageSize attribute for hidden inputs
  pageSizeInputs.forEach((pageSizeInput) => {
    pageSizeInput.setAttribute('value', `${pageSize}`)
  })

  loadMoreClickHandler()
}
