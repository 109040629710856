<template>
  <div :style="componentStyle" class="grid md:grid-cols-2 xl:grid-cols-map-grid">
    <div
      ref="search-form"
      :style="`top: ${searchBarTopPosition}px`"
      class="dfi-container-padding-x z-1 bg-beige-200 py-24 md:sticky"
    >
      <div class="relative">
        <svg aria-hidden="true" class="dfi-icon absolute left-0 top-1/3 text-neutral-600">
          <use :href="`${svgAssetPath}#search`"></use>
        </svg>
        <div
          ref="search-input-container"
          class="dfi-input-container relative block"
          data-component="dfi-input"
        ></div>
      </div>
    </div>
    <div :style="`top: ${searchBarTopPosition}px`" class="z-1 bg-beige-200 md:sticky"></div>
    <div :style="listContainerStyle" class="flex h-full flex-col bg-site-bg pt-24 md:pt-48">
      <div class="dfi-container-padding-x">
        <label class="sr-only" for="switch"> {{ labelSwitch }} </label>
        <div
          v-if="showSwitch"
          id="switch"
          :aria-checked="!isListView"
          class="dfi-switch z-0 mb-32"
          role="switch"
          tabindex="0"
          @click="toggleView"
          @keydown.prevent.space.enter="toggleView"
        >
          <span aria-hidden="true">{{ labelList }}</span>
          <span aria-hidden="true">{{ labelMap }}</span>
        </div>
        <p class="mb-24 text-14 text-neutral-600">
          {{ itemsVisibleCount }} {{ labelAssociationsComputed }}
        </p>
      </div>
      <div
        v-if="showList"
        class="dfi-container-padding-x border-t border-neutral-300 pt-32 md:border-none md:pt-0"
      >
        <AssociationListItem
          v-for="(item, i) in itemsVisible"
          :key="i"
          :aria-label="showMap ? `${labelZoomInOn} ${item.name}` : null"
          :association="item"
          :class="{ 'transition-colors hover:bg-tertiary': showMap }"
          :data-association-id="item.id"
          :label-close="labelClose"
          :label-link="labelLink"
          :role="showMap ? 'button' : null"
          :tabindex="showMap ? '0' : null"
          class="mb-16 last:mb-0"
          @click="showMap ? onListItemClick(item) : null"
          @keydown.prevent.space.enter="showMap ? onListItemClick(item) : null"
        />
      </div>
    </div>
    <div
      v-if="mapLoaded"
      :aria-hidden="!showMap"
      :class="showMap ? 'relative md:sticky' : 'pointer-events-none absolute z-0 opacity-0'"
      :style="mapContainerStyle"
      class="w-full border-t border-neutral-300 md:border-none"
    >
      <MapboxMap
        ref="mapboxMap"
        :accessToken="mapboxAccessToken"
        :center="initialCenter"
        :dragRotate="false"
        :max-bounds="[
          [0.0, 50.0],
          [21.0, 61.0]
        ]"
        :max-zoom="17"
        :min-zoom="initialZoom"
        :pitch-with-rotate="false"
        :style="mapHeight"
        :zoom="initialZoom"
        class="mapbox-map"
        language="da"
        map-style="mapbox://styles/firmaidraet/cm3mupq2d00q901qs7o5f4lry"
      >
        <MapboxCluster
          :clusters-paint="{
            'circle-color': colorBlue['800'],
            'circle-opacity': featureHoverConfig,
            'circle-radius': 22,
            'circle-stroke-width': 2,
            'circle-stroke-color': colorBlue['200']
          }"
          :data="geojson"
          :unclustered-point-layout="{
            'icon-image': 'custom-marker',
            'icon-size': 0.5
          }"
          :unclustered-point-paint="{
            'icon-opacity': featureHoverConfig
          }"
          unclustered-point-layer-type="symbol"
          @mb-feature-click="onFeatureClick($event)"
          @mb-feature-mouseenter="onFeatureMouseEnter($event)"
          @mb-feature-mouseleave="onFeatureMouseLeave()"
          @mb-cluster-click="scrollIntoView()"
        />
      </MapboxMap>
      <div class="dfi-container-padding-x absolute bottom-16 z-10 w-full">
        <AssociationListItem
          v-if="clickedItemOnMap"
          :association="clickedItemOnMap"
          :label-close="labelClose"
          :label-link="labelLink"
          :showCloseButton="true"
          class="bg-site-bg"
          @close="clearClickedItemOnMap()"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Association } from '@/stories/components/vue-components/associations-map/associations-map-model.js'
import AssociationListItem from '@/components/AssociationListItem.vue'
import 'mapbox-gl/dist/mapbox-gl.css'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../tailwind.config.js'
import { css } from 'code-tag'
import { isScreenMaxMd } from '@/utils/is-mobile'
import { headerHeight } from '@/utils/header-height'
import { defineAsyncComponent } from 'vue'
import { removeHasValueStyling, toggleInputHasValue } from '@/assets/scripts/components/input.ts'
import { getAssetsPath } from '@/utils/asset-path.ts'

export default {
  name: 'AssociationsMap',
  components: {
    AssociationListItem,
    MapboxMap: defineAsyncComponent(() =>
      import('@studiometa/vue-mapbox-gl').then((module) => module.MapboxMap)
    ),
    MapboxCluster: defineAsyncComponent(() =>
      import('@studiometa/vue-mapbox-gl').then((module) => module.MapboxCluster)
    )
  },
  props: {
    items: {
      type: Array as () => Association[],
      default: () => []
    },
    accessToken: {
      type: String || undefined
    },
    labelList: {
      type: String
    },
    labelMap: {
      type: String
    },
    labelAssociation: {
      type: String
    },
    labelAssociations: {
      type: String
    },
    labelZoomInOn: {
      type: String
    },
    labelClose: {
      type: String
    },
    labelLink: {
      type: String
    },
    labelSwitch: {
      type: String
    },
    placeholderSearch: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      mapLoaded: false,
      initialCenter: [11.0, 56.0] as [number, number],
      svgAssetPath: '',
      markerImageSrc: new URL('../assets/images/marker.png', import.meta.url).href,
      tailwindConfig: resolveConfig(tailwindConfig),
      colorBlue: tailwindConfig.theme?.colors?.blue as Object,
      fontSize: tailwindConfig.theme?.fontSize as Object,
      spacing: tailwindConfig.theme?.spacing as Object,
      itemsVisible: this.items as Association[],
      mapboxAccessToken: this.accessToken
        ? this.accessToken
        : import.meta.env.VITE_MAPBOX_ACCESS_TOKEN, // Use the environment variable if the prop is not set
      geojson: {
        type: 'FeatureCollection',
        features: this.items.map((item) => ({
          id: item.id,
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [item.longitude, item.latitude]
          },
          properties: item
        }))
      },
      featureHoverConfig: [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        0.8, // hover opacity
        1 // default opacity
      ],
      listItemHoverClass: 'bg-tertiary', // also used directly in the template
      isListView: isScreenMaxMd,
      clickedItemOnMap: null as Association | null,
      searchBarTopPosition: 0 as number,
      mapTopPosition: 0 as number,
      hoveredClusterId: null as number | null,
      hoveredFeatureId: null as number | null
    }
  },
  beforeMount() {
    this.svgAssetPath = getAssetsPath()
  },
  computed: {
    initialZoom() {
      return this.isMobile ? 5 : 6
    },
    itemsVisibleCount() {
      return this.itemsVisible.length
    },
    componentStyle() {
      return `scroll-margin-top: ${this.searchBarTopPosition}px` // Prevents the page from scrolling under the sticky search bar
    },
    mapHeight() {
      return this.isMobile ? 'height: 75vh' : 'height: 100%'
    },
    mapContainerStyle() {
      return this.isMobile
        ? ''
        : `top: ${this.mapTopPosition}px; height: calc(100vh - (${this.mapTopPosition}px + 1px))`
    },
    listContainerStyle() {
      return this.isMobile ? '' : `min-height: calc(100vh - (${this.mapTopPosition}px + 1px))`
    },
    showSwitch() {
      return this.isMobile
    },
    showList() {
      if (this.isMobile) return this.isListView
      return true
    },
    showMap() {
      if (this.isMobile) return !this.isListView
      return true
    },
    isMobile() {
      return isScreenMaxMd
    },
    labelAssociationsComputed() {
      return this.itemsVisibleCount === 1 ? this.labelAssociation : this.labelAssociations
    }
  },
  async mounted() {
    this.mapLoaded = true
    await this.$nextTick()
    await this.loadMapbox()
    this.setSearchBarTopPosition()
    this.setMapTopPosition()
  },
  methods: {
    getMapInstance() {
      if (this.$refs.mapboxMap) {
        return (this.$refs.mapboxMap as any).map
      }
      console.warn('MapboxMap is not yet loaded.')
      return null
    },
    debounce(func: Function, delay: number) {
      let timer: number | undefined

      return function (...args: any[]) {
        if (timer) {
          clearTimeout(timer)
        }

        timer = window.setTimeout(() => {
          func(...args)
        }, delay)
      }
    },
    async loadMapbox() {
      if (this.mapLoaded) {
        try {
          // Load mapbox async
          const { default: mapboxgl } = await import('mapbox-gl')

          // Search
          const { MapboxSearchBox } = await import('@mapbox/search-js-web')

          const map = this.getMapInstance()
          if (map) {
            console.log('Mapbox instance is ready:', map)

            // Search box styling
            const styling = css`
              .SearchIcon {
                display: none;
              }

              .Results {
                border: 2px solid ${this.colorBlue['900']};
                padding: ${this.spacing[24]};
                background-color: white;
                left: 0 !important;
                top: 60px !important;
              }

              .ResultsList {
                display: flex;
                flex-direction: column;
                gap: ${this.spacing[16]};
              }

              .Suggestion,
              .SuggestionName {
                transition: font-weight ${this.tailwindConfig.theme.transitionDuration.DEFAULT};
              }

              .Suggestion[aria-selected='true'] {
                background-color: white;
                font-weight: bold;
              }

              .Suggestion[aria-selected='true'] .SuggestionName {
                font-weight: bold;
              }

              .SuggestionText {
                font-size: ${this.fontSize[16][0]};
                line-height: ${this.fontSize[16][1].lineHeight};
              }

              .SuggestionName,
              .SuggestionDesc {
                display: inline;
              }

              .SuggestionName {
                margin-right: ${this.spacing[4]};
              }

              .ResultsAttribution {
                display: none;
              }
            `

            // Map loaded
            map.on('load', () => {
              map.addControl(new mapboxgl.NavigationControl({ showCompass: false }))

              this.setControlsIcons()
              if (this.isMobile) this.handleControlsHover()

              // Load the custom marker image
              map.loadImage(this.markerImageSrc, (error, image) => {
                if (error) {
                  console.error('Error loading marker image:', error)
                  return
                }
                if (image) {
                  map.addImage('custom-marker', image)
                }
              })

              // Initialize the search box
              const searchBox = new MapboxSearchBox()
              searchBox.accessToken = this.mapboxAccessToken
              searchBox.options = {
                country: 'dk',
                language: 'da',
                types: 'place,postcode'
              }
              searchBox.placeholder = this.placeholderSearch
              searchBox.marker = false
              searchBox.theme = {
                cssText: styling,
                variables: {
                  fontFamily: 'inherit',
                  fontWeightBold: 'normal',
                  padding: '0',
                  borderRadius: this.tailwindConfig.theme.borderRadius.xs,
                  boxShadow: 'none',
                  colorBackground: 'transparent',
                  colorBackgroundActive: 'transparent'
                }
              }

              // Handle the retrieve event
              searchBox.addEventListener('retrieve', (e) => {
                // Fit map bounds instantly if the map is not visible - else default to flyTo
                if (!this.showMap) {
                  const response = e.detail
                  map.fitBounds(response.features[0].properties.bbox, { animate: false })
                }

                this.clearClickedItemOnMap()
                this.scrollIntoView()
              })

              searchBox.mapboxgl = mapboxgl
              map.addControl(searchBox)

              this.$nextTick(() => {
                const searchElement = this.$el.querySelector('mapbox-search-box') as HTMLElement
                if (searchElement) {
                  // move the search element to search-form
                  const inputContainer = this.$refs['search-input-container'] as HTMLElement
                  inputContainer.appendChild(searchElement)

                  const input = inputContainer.querySelector('input') as HTMLInputElement
                  if (input) {
                    input.autocomplete = 'off' // disable browser autocomplete
                    input.name = 'search' // a hack to disable autocomplete in mobile Safari by adding "search" text in the name
                    input.type = 'search' // applies search input styling
                    input.classList.add('!pl-24') // adds padding to the left of the input
                    toggleInputHasValue(input) // handle has value styling
                  }

                  // Handle clearing the search box input
                  searchBox.addEventListener('clear', () => {
                    // zoom out to the initial zoom level if not already there
                    if (Math.round(map.getZoom()) !== this.initialZoom) {
                      map.flyTo({
                        center: this.initialCenter,
                        zoom: this.initialZoom,
                        animate: this.showMap
                      })
                    }

                    this.clearClickedItemOnMap()
                    if (input) removeHasValueStyling(input)
                    this.scrollIntoView()

                    // In list view - show all items
                    if (!this.showMap) this.itemsVisible = this.items
                  })
                }
              })

              // Handle cluster hover states
              map.on('mouseenter', 'mb-cluster-0-clusters', (e) => {
                const clusterId = e.features[0].properties.cluster_id
                this.hoveredClusterId = clusterId
                this.setMapFeatureState(clusterId, { hover: true })
              })

              map.on('mouseleave', 'mb-cluster-0-clusters', () => {
                if (this.hoveredClusterId) {
                  this.setMapFeatureState(this.hoveredClusterId, { hover: false })
                  this.hoveredClusterId = null
                }
              })

              // Update the visible items when the map is moved
              // Debounce prevents multiple calls when the map is moving
              map.on(
                'moveend',
                this.debounce(() => {
                  const bounds = map.getBounds() as mapboxgl.LngLatBounds
                  const itemsVisible = this.getVisibleItemsOnMap(bounds)

                  const currentIds = new Set(this.itemsVisible.map((item) => item.id))
                  const newIds = new Set(itemsVisible.map((item) => item.id))

                  if (
                    currentIds.size !== newIds.size ||
                    [...newIds].some((id) => !currentIds.has(id))
                  ) {
                    this.itemsVisible = itemsVisible

                    if (!this.isMobile) {
                      // calculate components top position in relation to the viewport
                      const windowTopPosition = window.scrollY || window.pageYOffset
                      const componentTopPosition =
                        this.$el.getBoundingClientRect().top + windowTopPosition

                      if (windowTopPosition > componentTopPosition) {
                        this.scrollIntoView(false) // scroll without animation to prevent footer flashing
                      } else if (windowTopPosition < componentTopPosition) {
                        this.scrollIntoView()
                      }
                    }
                  }
                }, 200)
              )
            })
          }
        } catch (error) {
          console.error('Error loading Mapbox resources:', error)
        }
      }
    },
    setControlsIcons(): void {
      const setButtonIcon = (selector, iconId) => {
        const button = this.$el.querySelector(selector) as HTMLElement
        if (button) {
          button.innerHTML = `<svg aria-hidden="true" class="dfi-icon-24">
            <use xlink:href="${this.svgAssetPath}#${iconId}"></use>
          </svg>`
        }
      }

      setButtonIcon('.mapboxgl-ctrl-zoom-in', 'plus')
      setButtonIcon('.mapboxgl-ctrl-zoom-out', 'minus')
    },
    handleControlsHover(): void {
      // Handle toggling hover styling on map controls in mobile view
      const controlsContainer = this.$el.querySelector('.mapboxgl-ctrl') as HTMLElement
      const buttons = controlsContainer.querySelectorAll('button')
      buttons.forEach((button) => {
        button.addEventListener('click', () => {
          // set timeout and remove hover styling after 3 seconds
          button.classList.add('hover')
          setTimeout(() => {
            button.classList.remove('hover')
          }, 1000)
        })
      })
    },
    calculateZoomAnimationDuration(currentZoom: number, targetZoom: number): number {
      const zoomDifference = Math.abs(currentZoom - targetZoom)
      let duration
      if (zoomDifference > 5) {
        duration = 1500 // Longer duration for larger zoom differences
      } else if (zoomDifference > 3) {
        duration = 1000 // Medium duration for moderate zoom differences
      } else {
        duration = 500 // Shorter duration for smaller zoom differences
      }
      return duration
    },
    getVisibleItemsOnMap(bounds: mapboxgl.LngLatBounds): Association[] {
      return this.items.filter((item) => {
        const coordinates = [item.longitude, item.latitude] as [number, number]
        return bounds?.contains(coordinates)
      })
    },
    setMapFeatureState(id: number, state: { hover: boolean }): void {
      const map = this.getMapInstance()
      map.setFeatureState({ source: 'mb-cluster-0-source', id }, state)
    },
    getListElementById(id: number): HTMLElement | null {
      return this.$el.querySelector(`[data-association-id="${id}"]`)
    },
    onFeatureMouseEnter(e): void {
      this.hoveredFeatureId = e.id
      // find the list item that corresponds to the hovered feature and highlight it
      const listItemEl = this.getListElementById(e.id)
      if (listItemEl) listItemEl.classList.add(this.listItemHoverClass)

      this.setMapFeatureState(e.id, { hover: true })
    },
    onFeatureMouseLeave(): void {
      if (this.hoveredFeatureId !== null) {
        // remove hover state from the feature
        this.setMapFeatureState(this.hoveredFeatureId, { hover: false })

        // find the list item that corresponds to the hovered feature and unhighlight it
        const listItemEl = this.getListElementById(this.hoveredFeatureId)
        if (listItemEl) listItemEl.classList.remove(this.listItemHoverClass) // remove hover styling
        this.hoveredFeatureId = null
      }
    },
    onFeatureClick(e): void {
      const coordinates = [e.geometry.coordinates[0], e.geometry.coordinates[1]] as [number, number]
      this.zoomIntoMapFeature(coordinates)

      // Show the clicked item on top of the map in mobile
      if (this.isMobile) this.clickedItemOnMap = e.properties as Association
      this.scrollIntoView()
    },
    onListItemClick(item: Association): void {
      const coordinates = [item.longitude, item.latitude] as [number, number]
      this.zoomIntoMapFeature(coordinates)
    },
    zoomIntoMapFeature(center: [number, number]): void {
      const map = this.getMapInstance()
      const targetZoom = 16
      const currentZoom = map.getZoom()
      const duration = this.calculateZoomAnimationDuration(currentZoom, targetZoom)

      map.flyTo({
        center,
        zoom: targetZoom,
        duration
      })
    },
    toggleView(): void {
      this.isListView = !this.isListView
      this.clearClickedItemOnMap()
    },
    clearClickedItemOnMap(): void {
      this.clickedItemOnMap = null
    },
    setSearchBarTopPosition() {
      this.searchBarTopPosition = headerHeight()
    },
    setMapTopPosition() {
      const searchForm = this.$refs['search-form'] as HTMLElement
      const searchFormHeight = searchForm?.offsetHeight || 0
      this.mapTopPosition = searchFormHeight + this.searchBarTopPosition
    },
    scrollIntoView(smooth = true): void {
      if (this.isMobile) return
      this.$el.scrollIntoView({ behavior: smooth ? 'smooth' : 'auto' })
    }
  }
}
</script>
<style lang="postcss">
/* Custom styles for Mapbox GL JS controls */
.mapbox-map {
  .mapboxgl-ctrl-group:not(:empty) {
    @apply bg-transparent shadow-none;
  }

  .mapboxgl-ctrl-top-right {
    @apply z-0;
  }

  .mapboxgl-ctrl {
    @apply mr-32 mt-32;
  }

  .mapboxgl-ctrl-group button {
    @apply size-fit min-h-[50px] min-w-[50px] rounded border border-solid border-blue-900 bg-site-bg p-12 transition-all hover:bg-blue-100;

    @media (hover: none) {
      &:not(:disabled):hover {
        @apply bg-site-bg;

        &.hover {
          @apply bg-blue-100;
        }
      }
    }

    &:disabled {
      @apply cursor-auto border-neutral-400 bg-white text-neutral-400;
    }

    &:first-of-type {
      @apply mb-8;
    }

    .mapboxgl-ctrl-icon {
      background-image: none;
    }
  }
}
</style>
