export const Accordion = () => {
  const toggleAccordion = (
    content: HTMLElement,
    icon: HTMLElement | null,
    button: HTMLButtonElement,
    accordionElement: HTMLElement
  ) => {
    const isOpen = content.style.maxHeight !== '0px'

    // Toggle styles
    content.style.maxHeight = isOpen ? '0px' : `${content.scrollHeight}px`
    content.style.opacity = isOpen ? '0' : '1'
    accordionElement.classList.toggle('border-b-2', !isOpen)
    accordionElement.classList.toggle('bg-quaternary/40', !isOpen)

    // Update aria-expanded attribute
    button.setAttribute('aria-expanded', isOpen ? 'false' : 'true')

    // Handle icon rotation
    if (icon) {
      icon.style.transform = isOpen ? 'rotate(0deg)' : 'rotate(90deg)'
      const child = icon.firstElementChild as HTMLElement | null
      if (child) {
        child.style.height = isOpen ? '24px' : '0'
        child.style.opacity = isOpen ? '1' : '0'
      }
    }
  }

  const initializeAccordion = () => {
    const accordionContainers = document.querySelectorAll(
      '[data-component="dfi-accordion-container"]'
    )
    accordionContainers.forEach((accordionContainer) => {
      const accordionElements = accordionContainer.querySelectorAll(
        '[data-component="dfi-accordion"]'
      )

      accordionElements.forEach((accordionElement) => {
        const button = accordionElement.querySelector('.dfi-accordion-button') as HTMLButtonElement
        const content = accordionElement.querySelector('.dfi-accordion-content') as HTMLElement
        const icon = button.querySelector('.dfi-accordion-icon') as HTMLElement | null

        if (button && content) {
          content.style.maxHeight = '0px'
          content.style.opacity = '0'
          content.style.transition = 'max-height 0.3s ease, opacity 0.3s ease'

          button.addEventListener('click', () =>
            toggleAccordion(content, icon, button, accordionElement as HTMLElement)
          )

          button.addEventListener('keydown', (e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault()
              toggleAccordion(content, icon, button, accordionElement as HTMLElement)
            }
          })
        }
      })
    })
  }

  initializeAccordion()
}
