import './assets/styles/main.css'
import './assets/scripts/main.ts'
import { LoadLoginModal } from './assets/scripts/vue-loaders/login-modal.ts'
import { LoadContactModal } from './assets/scripts/vue-loaders/contact-modal.ts'
import { LoadSearchModal } from './assets/scripts/vue-loaders/search-loader.ts'
import { LoadVideo } from './assets/scripts/vue-loaders/video-loader.ts'
import { LoadAssociationsMap } from './assets/scripts/vue-loaders/associations-map.ts'

function initializeComponents() {
  LoadLoginModal()
  LoadContactModal()
  LoadSearchModal()
  LoadVideo()
  LoadAssociationsMap()
}

document.addEventListener('DOMContentLoaded', initializeComponents)
