<template>
  <div class="flex flex-col rounded-sm border border-solid p-24">
    <div class="mb-8 flex items-baseline justify-between">
      <h2 class="subtitle-20 font-bold">{{ association.name }}</h2>
      <button v-if="showCloseButton" :aria-label="labelClose" type="button" @click="close">
        <svg aria-hidden="true" class="dfi-icon-24 relative fill-neutral-600">
          <use :href="`${svgAssetPath}#close`"></use>
        </svg>
      </button>
    </div>
    <address class="mb-32 flex flex-col not-italic">
      <span class="subtitle-16 mb-16">{{ association.address }}</span>
      <div class="mb-8 flex items-center gap-8">
        <svg aria-hidden="true" class="dfi-icon">
          <use :href="`${svgAssetPath}#phone`"></use>
        </svg>
        <a :href="'tel:' + phoneNumberFormatted(association.phoneNumber)">{{
          phoneNumberFormatted(association.phoneNumber)
        }}</a>
      </div>
      <div class="flex items-center gap-8">
        <svg aria-hidden="true" class="dfi-icon">
          <use :href="`${svgAssetPath}#email`"></use>
        </svg>
        <a :href="'mailto:' + association.email">{{ association.email }}</a>
      </div>
    </address>
    <a :href="association.link" class="dfi-button dfi-button-primary max-w-fit" target="_blank">
      <span class="relative">{{ labelLink }}</span>
    </a>
  </div>
</template>

<script lang="ts">
import { Association } from '@/stories/components/vue-components/associations-map/associations-map-model.js'
import { getAssetsPath } from '@/utils/asset-path.ts'

export default {
  name: 'AssociationListItem',
  props: {
    association: {
      type: Object as () => Association,
      required: true
    },
    showCloseButton: {
      type: Boolean,
      default: false
    },
    labelClose: {
      type: String
    },
    labelLink: {
      type: String
    }
  },
  data() {
    return {
      svgAssetPath: ''
    }
  },
  beforeMount() {
    this.svgAssetPath = getAssetsPath()
  },
  computed: {
    phoneNumberFormatted() {
      return (phoneNumber) => {
        // Remove all non-digit characters
        let cleaned = ('' + phoneNumber).replace(/\D/g, '')

        // Check if the number is in the format +45 12 34 56 78
        if (/^45\d{8}$/.test(cleaned)) {
          return `+45 ${cleaned.slice(2, 4)} ${cleaned.slice(4, 6)} ${cleaned.slice(6, 8)} ${cleaned.slice(8, 10)}`
        }

        // Check if the number is in the format 12 34 56 78
        if (/^\d{8}$/.test(cleaned)) {
          return `+45 ${cleaned.slice(0, 2)} ${cleaned.slice(2, 4)} ${cleaned.slice(4, 6)} ${cleaned.slice(6, 8)}`
        }

        // If the number does not match any format, return it as is
        return phoneNumber
      }
    }
  },
  methods: {
    close() {
      this.$emit('close') // handle close event in parent component
    }
  }
}
</script>
