<template>
  <div @click.prevent="showModal">
    <a
      href="/"
      class="group/link-with-arrow arrow-icon-animation text-firm flex flex-row-reverse items-center justify-end gap-8 text-14 font-bold text-blue-900 transition"
    >
      {{ buttonLabel }}
      <svg class="dfi-icon-24" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 15">
        <path
          class="arrow-icon-animation--line"
          fill="currentColor"
          d="M0 7.214h12.857v2.125H0z"
        ></path>
        <path
          class="arrow-icon-animation--arrow"
          fill="currentColor"
          d="M9.004 2.33a1.908 1.908 0 0 0-2.682 0l5.994 5.942-6.007 5.956c.74.734 1.941.734 2.682 0l5.48-5.434-.004-.005.526-.522L9.004 2.33Z"
        ></path>
      </svg>
    </a>
  </div>
  <VueModal ref="modal" :backButton="false">
    <slot />
  </VueModal>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import VueModal from '@/components/VueModal.vue'
import { CopyToClipboard } from '@/assets/scripts/components/copy-to-clipboard'

type PropTypes = {
  buttonLabel?: string
}

withDefaults(defineProps<PropTypes>(), {
  buttonLabel: 'Ansvarsområder'
})

const modal = ref<InstanceType<typeof VueModal>>()

const showModal = () => {
  modal.value?.open()
}

onMounted(() => {
  CopyToClipboard()
})
</script>
