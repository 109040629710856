export const Tab = () => {
  const setTabLine = (tabActive: HTMLLIElement | null) => {
    if (!tabActive) return

    const activeOptions: DOMRect = tabActive.getBoundingClientRect()

    const computedStyle = window.getComputedStyle(tabActive)
    const paddingRight = parseFloat(computedStyle.paddingRight)
    const paddingLeft = parseFloat(computedStyle.paddingLeft)

    if (activeOptions) {
      const { width } = activeOptions

      const tabLine: HTMLSpanElement | null = document.querySelector('.dfi-tab__line')

      if (tabLine) {
        tabLine.style.width = `${width - paddingLeft - paddingRight}px`
        tabLine.style.left = `${tabActive.offsetLeft + paddingLeft}px`
      }
    }
  }

  const tabs: NodeListOf<HTMLLIElement> = document.querySelectorAll('.dfi-tab')
  const contents = document.querySelectorAll('[data-component="dfi-tab-content"]')
  const tabActive: HTMLLIElement | null = document.querySelector('.dfi-tab--active')

  // set line to the active tab
  setTabLine(tabActive)

  tabs.forEach((tab) => {
    tab.addEventListener('click', () => {
      const target = tab.getAttribute('data-tab-target')

      // Remove active class from all tabs
      tabs.forEach((t) => t.classList.remove('dfi-tab--active'))
      tab.classList.add('dfi-tab--active')

      // Hide all content sections
      contents.forEach((content) => {
        content.classList.remove('dfi-tab__content--active')
      })

      // Show the target content with fade-in
      const targetContent: HTMLDivElement | null = document.querySelector(
        `[data-tab-content="${target}"]`
      )
      targetContent?.classList.add('dfi-tab__content--active')

      // move the line
      setTabLine(tab)
    })
  })
}
