// Dynamically import the lottie object and separately import the AnimationItem type
import type { AnimationItem } from 'lottie-web'

let animItem: AnimationItem | null = null
let loaderElement: HTMLElement | null = null

export const Loader = async () => {
  loaderElement = document.querySelector('[data-component="dfi-loader"]')

  if (loaderElement) {
    // Dynamically import lottie-web
    const lottie = (await import('lottie-web')).default // This is added to its own file (vite magic and lazy loaded correctly)

    animItem = lottie.loadAnimation({
      container: loaderElement,
      loop: true,
      path: '/dist/loader.json',
      renderer: 'svg',
      autoplay: false
    })
  }
}

export function playLoader(): void {
  loaderElement?.classList.add('active')
  animItem?.play()
}

export function stopLoader(): void {
  loaderElement?.classList.remove('active')
  animItem?.stop()
}
