import { Meta } from '@storybook/vue3'
import { SearchItem as ISearchItem } from './search-model'

const meta = {
  title: 'Components/Search',
  args: {
    url: 'https://google.com',
    title: 'Page title',
    parentTitle: 'Hockey',
    intro:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua'
  } as ISearchItem
} as Meta
export default meta

const Template = (args: ISearchItem) => {
  return {
    template: `
    <li class='dfi-search__list-item'>
      <a class='dfi-search__list-link' href='${args.url}'>
        <svg class="dfi-icon-24 dfi-search__list-link-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 15">
          <path fill="currentColor" d="M0 7.214h12.857v2.125H0z" />
          <path fill="currentColor" d="M9.004 2.33a1.908 1.908 0 0 0-2.682 0l5.994 5.942-6.007 5.956c.74.734 1.941.734 2.682 0l5.48-5.434-.004-.005.526-.522L9.004 2.33Z" />
        </svg>
        <div class='dfi-search__list-link-animation'>
          <span class='inline-block text-12 xl:text-14 mb-4 xl:mb-8 uppercase'>${args.parentTitle}</span>
          <span class='inline-block text-16 xl:subtitle-20 mb-4 xl:mb-8 font-bold'>${args.title}</span>
          <span class='text-neutral-700 text-14 xl:text-16 font-sans'>${args.intro}</span>
        </div>
      </a>
    </li>
  `
  }
}

export const SearchItem = Template.bind({})
