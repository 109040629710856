import { Meta } from '@storybook/vue3'
import { OverviewGridItem as IOverviewGridItem } from './overview-grid-item-model'
import { Tag } from '@/stories/components/tag/tag.stories.ts'

const meta = {
  title: 'Components/Overview Grid',
  args: {
    url: 'https://google.com',
    isExternalLink: false,
    title: 'Firmaidræt Open',
    subTitle: '22. oktober - 18. december 2024',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    pictureSourceSrcSet:
      'https://picsum.photos/seed/picsum/332/410 320w,https://picsum.photos/seed/picsum/663/820 730w,https://picsum.photos/seed/picsum/507/608 731w',
    pictureImageSrc: 'https://picsum.photos/seed/picsum//500',
    pictureAlt: 'Picture alt text',
    logoUrl: 'https://dummyimage.com/120x40/FFF/000.svg',
    logoAlt: 'Logo alt text',
    dueDateLabel: 'Tilmeldingsfrist',
    dueDate: '1. oktober',
    isExpiredDueDate: false
  } as IOverviewGridItem
} as Meta
export default meta

const Template = (args: IOverviewGridItem) => {
  const tag = Tag({
    dueDateLabel: args.dueDateLabel,
    dueDate: args.dueDate,
    isExpiredDueDate: args.isExpiredDueDate
  }).template

  return {
    template: `

    <!-- This markup is dynamically rendered in production when the "Load More" button is clicked. It uses JSON data retrieved from the backend to append additional event items to the list. -->
    <li class="list-none">
      <a 
        ${args.isExternalLink ? 'aria-label="Dette link åbner i en ny fane" target="_blank" rel="noopener"' : ''} 
        class='relative flex flex-col group/image-hover hover:text-neutral-600 transition' 
        href='${args.url}'>
        <div class="relative mb-16">
          <picture class="block h-full w-full aspect-5/6 overflow-hidden dfi-transparent-overlay rounded-sm">
            <source srcset="${args.pictureSourceSrcSet}">
            <img src="${args.pictureImageSrc}" 
              loading="lazy" alt="${args.pictureAlt}" 
              class="block group-hover/image-hover:scale-105 transition h-full w-full object-cover object-top rounded-sm">
          </picture>
      
          <!-- Logo is optional - Event overview -->
          ${
            args.logoUrl
              ? `<img class="absolute right-16 bottom-16 max-w-[120px] max-h-[40px]" src="${args.logoUrl}" alt="${args.logoAlt}">`
              : ''
          }
        </div>
      
        <!-- Subtitle can be text or a date string -->
        <span class="text-12 mb-8 uppercase">${args.subTitle}</span>
        <div class="mb-8">
          <span class="subtitle-20 font-bold">
            ${args.title}${args.isExternalLink ? `<svg class="size-[20px] inline-block ml-8" aria-hidden="true"><use xlink:href="/dist/assets/icons.svg#external-link"></use></svg>` : ''}
          </span>
        </div>
      
        <!-- Description is optional - Event overview/Case overview -->
        ${args.description ? `<span class="text-16">${args.description}</span>` : ''}   
      
        <!-- Only render the tag if there's a dueDateLabel -->
        ${args.dueDateLabel ? `<div class="absolute top-16 right-16">${tag}</div>` : ''}
      </a>
    </li>
  `
  }
}

export const OverviewGridItem = Template.bind({})
