import { Association } from '@/stories/components/vue-components/associations-map/associations-map-model.ts'
import { createApp, h } from 'vue'
import AssociationsMap from '@/components/AssociationsMap.vue'

export const LoadAssociationsMap = async () => {
  const appElements = document.querySelectorAll('[data-vue-app="associations-map-component"]')

  if (!appElements) {
    console.error('Associations map component not found in the DOM')
    return
  }

  appElements.forEach((appElement) => {
    const associations = JSON.parse(
      appElement.getAttribute('data-associations') || '[]'
    ) as Association[]
    const associationsWithIds = associations.map((item, index) => ({
      ...item,
      id: index
    }))

    // Create and mount the Vue app to the selected element
    createApp({
      render: () =>
        h(AssociationsMap, {
          items: associationsWithIds,
          accessToken: appElement.getAttribute('data-access-token') || undefined,
          labelList: appElement.getAttribute('data-label-list') || '',
          labelMap: appElement.getAttribute('data-label-map') || '',
          labelAssociation: appElement.getAttribute('data-label-association') || '',
          labelAssociations: appElement.getAttribute('data-label-associations') || '',
          labelZoomInOn: appElement.getAttribute('data-label-zoom-in-on') || '',
          labelClose: appElement.getAttribute('data-label-close') || '',
          labelLink: appElement.getAttribute('data-label-link') || '',
          ariaLabelSwitch: appElement.getAttribute('data-label-switch') || '',
          placeholderSearch: appElement.getAttribute('data-placeholder-search') || ''
        })
    }).mount(appElement)
  })
}
