export const smoothScrollTo = (targetY: number, duration: number = 500): void => {
  const startY: number = window.scrollY
  const distance: number = targetY - startY
  const startTime: number = performance.now()

  const scroll = (currentTime: number): void => {
    const elapsedTime: number = currentTime - startTime
    const progress: number = Math.min(elapsedTime / duration, 1)

    // Easing function (ease-in-out)
    const easeInOutQuad = (t: number): number => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t)
    const easeProgress: number = easeInOutQuad(progress)

    window.scrollTo(0, startY + distance * easeProgress)

    if (progress < 1) {
      requestAnimationFrame(scroll)
    }
  }

  requestAnimationFrame(scroll)
}
