<template>
  <button :aria-label="ariaLabel" :class="buttonClasses" type="button" @click="showModal">
    <svg class="h-16 w-16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.5 10.5a10 10 0 1 0 20 0 10 10 0 1 0-20 0ZM23.5 23.5l-5.929-5.929"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        vector-effect="non-scaling-stroke"
      ></path>
    </svg>
  </button>
  <VueModal ref="modal" :backButton="false">
    <slot></slot>
  </VueModal>
</template>

<script lang="ts" setup>
import { computed, nextTick, onMounted, ref } from 'vue'
import VueModal from '@/components/VueModal.vue'
import { ClearSearch } from '@/assets/scripts/components/search-clear-buttons'
import { toggleInputHasValue } from '@/assets/scripts/components/input'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

const breakpoints = useBreakpoints(breakpointsTailwind)
const isMobile = breakpoints.smaller('xl')

const modal = ref<InstanceType<typeof VueModal>>()
let searchModalInput: HTMLInputElement | null

type PropTypes = {
  ariaLabel: string
}

withDefaults(defineProps<PropTypes>(), {
  ariaLabel: 'Open search modal'
})

const focusInput = async () => {
  // Wait for the next frame to ensure the modal is rendered before focusing
  await nextTick()

  setTimeout(() => {
    searchModalInput?.focus()
  }, 300)
}

const showModal = () => {
  modal.value?.open()
  focusInput()
}

const buttonClasses = computed(() =>
  isMobile.value
    ? 'block p-8 text-blue-900 transition -mr-16 group-[.mobile-menu-is-open]:pointer-events-none group-[.mobile-menu-is-open]:opacity-0'
    : 'dfi-button dfi-button-secondary dfi-button-icon-only dfi-button-icon-only-with-animation'
)

onMounted(() => {
  ClearSearch()

  searchModalInput = modal.value?.modalEl?.querySelector('[data-component="dfi-search"]')
  if (searchModalInput) toggleInputHasValue(searchModalInput)
})
</script>
