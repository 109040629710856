import { createApp } from 'vue'
import SearchModal from '../../../components/SearchModal.vue'

export const LoadSearchModal = () => {
  const appElements: NodeListOf<HTMLElement> = document.querySelectorAll(
    '[data-vue-app="search-modal-component"]'
  )

  appElements.forEach((appElement) => {
    // Create and mount the Vue app to the selected element
    createApp({
      components: { SearchModal },
      template: appElement.innerHTML // Use inner HTML to capture slotted content
    }).mount(appElement)
  })
}
