<template>
  <div class="dfi-container-padding-x dfi-container-padding-y mx-auto max-w-medium">
    <div
      class="grid gap-y-40 max-lg:flex-col max-lg:items-start lg:grid-cols-3 lg:items-end lg:gap-x-content-block-gap"
    >
      <div v-if="caption || heading || description" class="col-span-1 md:max-w-[70vw] lg:flex-1">
        <div class="mb-16 uppercase">{{ caption }}</div>
        <h2 class="title-30 mb-16">
          {{ heading }}
        </h2>
        <div class="dfi__rich-text" v-html="description"></div>
      </div>
      <div
        :class="`w-full lg:max-w-none lg:flex-1 ${caption || heading || description ? 'col-span-2' : 'col-span-full'}`"
      >
        <div
          :title="title"
          tabindex="0"
          @click="addIframe"
          @mouseover="warmConnections"
          @keyup.enter="addIframe"
          @keyup.space="addIframe"
        >
          <div
            ref="domRefFrame"
            class="group relative z-0 aspect-video size-full cursor-pointer overflow-hidden rounded-sm bg-blue-900"
          >
            <!-- Overlay  -->
            <div
              v-if="!iframeLoaded"
              class="absolute z-10 size-full bg-blue-900 object-cover opacity-60"
            ></div>

            <!-- Video thumbnail -->
            <picture v-if="(!iframeLoaded && imageUrlWebp) || imageUrlJpeg">
              <source :srcset="imageUrlWebp" type="image/webp" />
              <source :srcset="imageUrlJpeg" type="image/jpeg" />
              <img
                :alt="title"
                :aria-label="title"
                :src="imageUrlJpeg"
                class="z-0 size-full object-cover object-center"
                decoding="async"
                loading="lazy"
              />
            </picture>

            <!-- Play button -->
            <button
              v-if="!iframeLoaded"
              :aria-label="title"
              class="absolute left-1/2 top-1/2 z-30 h-64 w-64 -translate-x-1/2 -translate-y-1/2 overflow-hidden rounded-full bg-primary transition-colors duration-mobileMenu group-hover:bg-secondary"
              @click="addIframe"
              v-on:touchstart.passive="addIframe"
            >
              <svg
                aria-hidden="true"
                class="dfi-icon-24 absolute left-[22px] top-1/2 -translate-y-1/2"
              >
                <use :href="`${svgAssetPath}#play`"></use>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeMount, onMounted, ref, watch } from 'vue'
import { isMobile } from '@/utils/is-mobile'
import { getAssetsPath } from '@/utils/asset-path.ts'

type Props = {
  caption?: string | null
  heading?: string | null
  description?: string | null
  videoUrl: string
  videoTitle?: string
  playLabel?: string
  youtubeParams?: string
  autoplay?: boolean
  startAt?: number
}

const props = withDefaults(defineProps<Props>(), {
  caption: null,
  heading: null,
  description: null,
  videoTitle: 'Video',
  playLabel: 'Play',
  youtubeParams: '',
  autoplay: false,
  startAt: 0
})

const svgAssetPath = ref<string | null>(null)
const iframeLoaded = ref(false)
const title = ref(`${props.playLabel}: ${props.videoTitle}`)
const imageUrlWebp = ref('')
const imageUrlJpeg = ref('')
const domRefFrame = ref<HTMLElement | null>(null)
const hasPlayed = ref(false)
let preconnected = false

const videoId = ref('')
const videoProvider = ref('')

const preconnectUrls = {
  youtube: [
    'https://s.ytimg.com',
    'https://www.youtube.com',
    'https://www.google.com',
    'https://googleads.g.doubleclick.net',
    'https://static.doubleclick.net'
  ],
  vimeo: ['https://f.vimeocdn.com', 'https://player.vimeo.com', 'https://i.vimeocdn.com']
}

// Extract video ID and provider from the URL
const extractVideoData = (url) => {
  const youtubeRegex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
  const vimeoRegex = /(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com\/(?:video\/|[^/\n\s]+\/|\d+))/
  let match

  if ((match = url.match(youtubeRegex))) {
    videoProvider.value = 'youtube'
    videoId.value = match[1]
  } else if ((match = url.match(vimeoRegex))) {
    videoProvider.value = 'vimeo'
    videoId.value = url.split('/').pop()
  }
}

// Initialize the image placeholder
const initImagePlaceholder = () => {
  const providerUrls = {
    youtube: () => {
      imageUrlWebp.value = `https://i.ytimg.com/vi_webp/${videoId.value}/hqdefault.webp`
      imageUrlJpeg.value = `https://i.ytimg.com/vi/${videoId.value}/hqdefault.jpg`
    },
    vimeo: async () => {
      try {
        const response = await fetch(`https://vimeo.com/api/v2/video/${videoId.value}.json`)
        const data = await response.json()
        const imgId = data[0].thumbnail_large.split('/').pop().split('_')[0]
        imageUrlWebp.value = `https://i.vimeocdn.com/video/${imgId}.webp?mw=1100&mh=619&q=70`
        imageUrlJpeg.value = `https://i.vimeocdn.com/video/${imgId}.jpg?mw=1100&mh=619&q=70`
      } catch (error) {
        console.error('Failed to load Vimeo thumbnail:', error)
      }
    }
  }

  prefetchUrls(videoProvider.value)
  providerUrls[videoProvider.value]?.()
}

// Prefetch connection URLs for the video provider
const prefetchUrls = (provider) => {
  preconnectUrls[provider]?.forEach((url) => addPrefetch('preconnect', url))
}

// Create iframe element and load video
const addIframe = async () => {
  if (!iframeLoaded.value && !hasPlayed.value) {
    const srcUrl =
      videoProvider.value === 'youtube'
        ? `https://www.youtube-nocookie.com/embed/${videoId.value}?rel=0&autoplay=1&mute=1&start=${props.startAt}&${props.youtubeParams}&modestbranding=1`
        : `https://player.vimeo.com/video/${videoId.value}?autoplay=1&muted=1&#t=${props.startAt}`

    const iframe = document.createElement('iframe')
    iframe.src = srcUrl
    iframe.title = title.value
    iframe.setAttribute('webkitallowfullscreen', '') // Safari
    iframe.setAttribute('mozallowfullscreen', '') // Firefox
    iframe.allow =
      'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen'
    iframe.allowFullscreen = true
    iframe.style.border = 'none'
    iframe.classList.add(
      'absolute',
      'aspect-video',
      'w-full',
      'h-full',
      'top-0',
      'left-0',
      'right-0',
      'bottom-0'
    )

    if (domRefFrame.value) {
      domRefFrame.value.appendChild(iframe as Element)
      iframeLoaded.value = true
      hasPlayed.value = true

      if (isMobile) {
        // Delay to ensure iframe is properly attached to DOM before fullscreen request
        setTimeout(async () => {
          // Try the standard fullscreen API
          if (domRefFrame.value && domRefFrame.value.requestFullscreen) {
            await domRefFrame.value.requestFullscreen()
          }
          // Fallback for Safari (older versions)
          else if ((domRefFrame.value as any).webkitRequestFullscreen) {
            await (domRefFrame.value as any).webkitRequestFullscreen()
          }
          // Fallback for Firefox (older versions)
          else if ((domRefFrame.value as any).mozRequestFullScreen) {
            await (domRefFrame.value as any).mozRequestFullScreen()
          }
          // Fallback for older Microsoft browsers
          else if ((domRefFrame.value as any).msRequestFullscreen) {
            await (domRefFrame.value as any).msRequestFullscreen()
          }
          // Another Safari fallback for video elements
          else if ((domRefFrame.value as any).webkitEnterFullscreen) {
            await (domRefFrame.value as any).webkitEnterFullscreen()
          }
        }, 100)
      }
    }
  }
}

const warmConnections = () => {
  if (preconnected) return
  prefetchUrls(videoProvider.value)
  preconnected = true
}

const addPrefetch = (kind, url) => {
  const link = document.createElement('link')
  link.rel = kind
  link.href = url
  link.crossOrigin = 'true'
  document.head.appendChild(link)
}

// Watch for changes in the video URL prop
watch(
  () => props.videoUrl,
  (newUrl) => {
    extractVideoData(newUrl)
    initImagePlaceholder()
  },
  { immediate: true }
)

onBeforeMount(() => {
  svgAssetPath.value = getAssetsPath()
})

// Initialize and handle autoplay functionality with IntersectionObserver
onMounted(() => {
  if (props.autoplay) initIntersectionObserver()
})

const initIntersectionObserver = () => {
  if ('IntersectionObserver' in window) {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !iframeLoaded.value) {
          warmConnections()
          addIframe()
          observer.unobserve(domRefFrame.value as Element)
        }
      })
    })
    observer.observe(domRefFrame.value as Element)
  }
}
</script>
