export const RichText = () => {
  const richTextElements = document.querySelectorAll('[data-component="dfi-rich-text"]')

  richTextElements.forEach((richTextElement) => {
    const richTextContent = richTextElement.getAttribute('data-content')

    if (richTextContent) {
      // TODO maybe make sense to sanitize the content here.
      richTextElement.innerHTML = richTextContent
      richTextElement.setAttribute('data-content', '')
    }
  })
}
