import FetchWrapper from '@/utils/api.ts'
import { OverviewGridItem as OverviewGridItemHtml } from '@/stories/components/overview-grid/overview-grid-item.stories'
import {
  OverviewGridItem,
  OverviewGridItemData
} from '@/stories/components/overview-grid/overview-grid-item-model.ts'
import { playLoader, stopLoader } from '@/assets/scripts/components/loader.ts'

// This module handles the dynamic rendering of an overview grid for events, news and cases.
// It fetches data from a backend service, renders additional items when the
// "Load More" button is clicked, and updates the UI with the retrieved data.

export const OverviewGrid = () => {
  // Selects the "Load More" button and the results label from the DOM. These elements are updated dynamically based on the backend response.
  const loadMoreButton: HTMLButtonElement | null = document.querySelector(
    '[data-component="dfi-overview-grid-load-button"]'
  )
  const resultsLabel: HTMLElement | null = document.querySelector(
    '[data-component="dfi-overview-grid-results-label"]'
  )

  // Hides the "Load More" button if there are no more items to load. This is called when the backend returns no `loadMoreApiUrl`.
  const hideLoadMoreButton = () => {
    if (loadMoreButton) {
      loadMoreButton.classList.add('!hidden')
    }
  }

  // Updates the "Load More" button with a new API URL for the next batch of items. This is used to fetch the next set of results dynamically.
  const updateLoadMoreButtonApiUrl = (url: string) => {
    if (loadMoreButton && url) {
      loadMoreButton.setAttribute('data-api-url', url)
    }
  }

  // Updates the UI after a successful "Load More" action:
  // - Hides the "Load More" button if no further results are available.
  // - Updates the results label with new pagination information.
  // - Updates the API URL for the next fetch, if provided by the backend.
  const updateInfoAfterLoadMoreAction = (response: OverviewGridItemData) => {
    if (!response.loadMoreApiUrl) {
      hideLoadMoreButton()
    }

    if (resultsLabel) {
      resultsLabel.innerText = response.loadMoreResultsLabel
    }
    updateLoadMoreButtonApiUrl(response.loadMoreApiUrl)
  }

  const rendererItem = (item: OverviewGridItem) => {
    // This markup is dynamically rendered in production when the "Load More" button is clicked.
    // It uses JSON data retrieved from the backend to append additional items to the list
    // Note: The initial 16 items displayed on the page are rendered from the Razor view file and not from the Storybook item definition.
    return OverviewGridItemHtml(item).template
  }

  const updateOverviewGridResultHtml = (response: OverviewGridItemData) => {
    const eventOverviewList: HTMLElement | null = document.querySelector(
      '[data-component="dfi-overview-grid"]'
    )
    // Extract and map new items into HTML.
    const newItems = response?.items
      .map((item) => rendererItem(item)) // Convert items to HTML.
      .join('')

    // Inject new items into the DOM.
    if (eventOverviewList) {
      eventOverviewList.insertAdjacentHTML('beforeend', newItems)
    }

    // Update the UI to reflect update or hide the "Load More" button.
    updateInfoAfterLoadMoreAction(response)
  }

  // Fetches data from the specified URL using the FetchWrapper utility.
  // This function returns a promise that resolves to an OverviewGridItemData object,
  // containing the items details such as the loadMoreApiUrl and results label.
  const load = async (url: string): Promise<OverviewGridItemData> => {
    const api = new FetchWrapper()

    return api.get(url)
  }

  // Sets up the click handler for the "Load More" button.
  // When clicked, it:
  // - Fetches the next batch of data from the API URL set on the button.
  // - Displays a loading indicator during the fetch.
  // - Appends the fetched items to the grid and updates the UI
  // - Stops the loader once the fetch is complete or an error occurs.
  const loadMoreClickHandler = async () => {
    if (loadMoreButton) {
      loadMoreButton.addEventListener('click', async (e: Event) => {
        e.preventDefault()

        try {
          const url = loadMoreButton.getAttribute('data-api-url')

          if (url) {
            playLoader()
            const loadResponse = await load(url)

            updateOverviewGridResultHtml(loadResponse)
          }
        } catch (error) {
          console.error(error)
        } finally {
          stopLoader()
        }
      })
    }
  }

  if (!loadMoreButton?.getAttribute('data-api-url')) {
    hideLoadMoreButton()
  }

  loadMoreClickHandler().catch((error) => {
    console.error('Unhandled error in loadMoreClickHandler:', error)
  })
}
