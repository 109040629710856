import { Header } from './header.ts'
import { Tab } from './tab.ts'
import { Anchor } from './anchor.ts'
import { CopyToClipboard } from './copy-to-clipboard.ts'
import { Search } from './search.ts'
import { RichText } from './rich-text.ts'
import { HeroScrollAnimation } from '@/assets/scripts/components/hero-scroll-animation.ts'
import { Input } from '@/assets/scripts/components/input.ts'
import { Newsletter } from '@/assets/scripts/components/newsletter.ts'
import { OverviewGrid } from '@/assets/scripts/components/overview-grid.ts'
import { Accordion } from '@/assets/scripts/components/accordion.ts'
import { Loader } from '@/assets/scripts/components/loader.ts'

function initializeComponents() {
  Header()
  Tab()
  Anchor()
  CopyToClipboard()
  Search()
  RichText()
  HeroScrollAnimation()
  Input()
  Newsletter()
  OverviewGrid()
  Accordion()
  Loader()
  // Conditionally import ScrollToUmbracoForm only if needed
  if (document.querySelector('.umbraco-forms-form, .umbraco-forms-submitmessage')) {
    import('@/assets/scripts/components/scroll-to-umbraco-form.ts')
      .then((module) => {
        module.ScrollToUmbracoForm()
      })
      .catch((error) => console.error('Failed to load ScrollToUmbracoForm:', error))
  }
}

export function setupEventListeners() {
  if (document.readyState !== 'loading') {
    initializeComponents()
  } else {
    document.addEventListener('DOMContentLoaded', initializeComponents, { once: true })
  }
}

setupEventListeners()
