import { inputHasValue } from '@/assets/scripts/components/input'

export const isValid = (input: HTMLInputElement | HTMLTextAreaElement) => {
  // If the input is not required return true
  if (input.required === false) return true

  // If the input has a validation pattern, validate against it
  if (input.dataset.validation) {
    const regex = new RegExp(input.dataset.validation)
    return regex.test(input.value)
  }

  // If the input is required and has no validation pattern, check if it has a value
  if (input.required) {
    if (input instanceof HTMLInputElement && input.type === 'checkbox') {
      return input.checked
    }
    return inputHasValue(input)
  }

  return true
}
