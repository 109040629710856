import { isValid } from '@/utils/input-validation'
import FetchWrapper from '@/utils/api.ts'
import { headerHeight } from '@/utils/header-height'

export const Newsletter = () => {
  const newsletterElement: HTMLElement | null = document.querySelector(
    '[data-component="dfi-newsletter"]'
  )
  if (!newsletterElement) return

  // Toggle button state
  const inputs = newsletterElement.querySelectorAll('input') as NodeListOf<HTMLInputElement>
  const button = newsletterElement.querySelector('button') as HTMLButtonElement

  const toggleButtonState = () => {
    // Check if all inputs are valid
    if (button) {
      button.disabled = !allValid()
    }
  }

  const allValid = () =>
    Array.from(inputs).every((input) => {
      return isValid(input)
    })

  inputs.forEach((input) => {
    input.addEventListener('input', toggleButtonState)
    if (input.type === 'checkbox') {
      input.addEventListener('change', toggleButtonState)
    }
  })

  toggleButtonState()

  // Submit form
  const handleSubmit = async (event: Event) => {
    event.preventDefault()

    // check if all inputs are valid
    if (!allValid()) return

    button.disabled = true

    const api = new FetchWrapper()

    const formData = new FormData(form)
    const signupData = {
      name: formData.get('Name') as string,
      email: formData.get('Email') as string,
      selectedList: formData.getAll('SelectedList') as string[]
    }

    api
      .post('/newsletter/signup/', signupData)
      .then(() => {
        form.reset()
        // Show success message
        newsletterElement.classList.remove('sign-up--error')
        newsletterElement.classList.add('sign-up--success')

        // Find element to scroll to
        const scrollToEl =
          (newsletterElement.querySelector('[data-caption]') as HTMLElement) ||
          (newsletterElement.querySelector('.dfi-newsletter-success-message') as HTMLElement)

        if (scrollToEl) {
          scrollToEl.style.scrollMarginTop = `${headerHeight() + 20}px` // add scroll margin with header height and 20px
          scrollToEl.scrollIntoView({ behavior: 'smooth' })
        }
      })
      .catch((error) => {
        console.error('Signup failed:', error.message)
        // Show error message
        newsletterElement.classList.add('sign-up--error')
      })
      .finally(() => {
        button.disabled = false
      })
  }

  const form = newsletterElement.querySelector('form') as HTMLFormElement
  form?.addEventListener('submit', handleSubmit)
}
