import { createApp } from 'vue'
import VideoComopnent from '../../../components/VideoComponent.vue'

export const LoadVideo = () => {
  const appElements = document.querySelectorAll('[data-vue-app="video-component"]')

  if (appElements.length === 0) {
    console.log('No video components found!')
    return // Early exit if no elements are found
  }

  appElements.forEach((appElement) => {
    // Prepare a Vue instance and mount it on each element
    createApp(VideoComopnent, {
      videoUrl: appElement.getAttribute('data-video-url') || '',
      caption: appElement.getAttribute('data-caption') || '',
      heading: appElement.getAttribute('data-heading') || '',
      description: appElement.getAttribute('data-description') || ''
    }).mount(appElement)
  })
}
