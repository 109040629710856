type FetchConfig = {
  baseUrl?: string
  headers?: HeadersInit
  defaultOptions?: RequestInit
}

export default class FetchWrapper {
  private baseUrl: string
  private headers: HeadersInit
  private defaultOptions: RequestInit

  constructor(config: FetchConfig = {}) {
    this.baseUrl = config.baseUrl || ''
    this.headers = config.headers || {}
    this.defaultOptions = config.defaultOptions || {}
  }

  private async request<T>(endpoint: string, options: RequestInit): Promise<T> {
    const url = `${this.baseUrl}${endpoint}`
    const mergedOptions: RequestInit = {
      ...this.defaultOptions,
      ...options,
      headers: {
        ...this.headers,
        ...options.headers
      }
    }

    try {
      const response = await fetch(url, mergedOptions)
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      // Check if the response body is empty
      const text = await response.text()
      if (!text) {
        return {} as T // Return an empty object if the response body is empty
      }

      return JSON.parse(text) as T
    } catch (error: any) {
      throw new Error(`Fetch failed: ${error.message}`)
    }
  }

  public async get<T>(endpoint: string, options: RequestInit = {}): Promise<T> {
    return this.request<T>(endpoint, {
      ...options,
      method: 'GET'
    })
  }

  public async post<T>(endpoint: string, body: any, options: RequestInit = {}): Promise<T> {
    return this.request<T>(endpoint, {
      ...options,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...options.headers
      },
      body: JSON.stringify(body)
    })
  }
}
